import { useMutation } from "@tanstack/react-query";

import {
  ChangeBillingPeriodRequest,
  ChangeBillingPeriodResponse,
  changeBillingPeriod,
} from "../graphql/changeBillingPeriod";

export const useChangeBillingPeriod = () =>
  useMutation<ChangeBillingPeriodResponse, Error, ChangeBillingPeriodRequest>({
    mutationKey: ["changeBillingPeriod"],
    mutationFn: async (vars) => {
      const response = await changeBillingPeriod(vars);
      return response.changeBillingPeriod;
    },
  });
