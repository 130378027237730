import { useQueries, UseQueryOptions } from "@tanstack/react-query";
import { AccountApi, Configuration } from "oura-account-api-client";

const api = new AccountApi(
  new Configuration({ basePath: import.meta.env.VITE_ACCOUNT_API_URL }),
);

export interface InvoiceGetResponse {
  file: File;
  invoiceId: string;
}

export const useGetInvoice = (invoices: string[]) => {
  return useQueries({
    queries: invoices.map<UseQueryOptions<InvoiceGetResponse>>((invoiceId) => ({
      queryKey: ["getInvoices", invoiceId],
      queryFn: async (): Promise<InvoiceGetResponse> => {
        const response = await api.getInvoice(
          { invoiceId: invoiceId },
          { withCredentials: true, responseType: "blob" },
        );
        return { file: response.data, invoiceId };
      },
      retry: false,
      staleTime: Infinity,
      enabled: false,
    })),
  });
};
