import { styled } from "styled-components";

import { Link, LinkProps } from "apps-common/components/Link";
import linkIcon from "ui/components/assets/linkIcon.svg";

export type LinkTextColor = "blueLight" | "lightRed" | "bannerErrorState";

const LinkSectionBody = styled.p<{
  color: LinkTextColor;
}>(
  ({ theme, color }) => `
  display: flex;
  font-family: ${theme.fonts};
  font-weight: 700;
  font-size: 16px;
  color: ${theme.colors[color]};
  line-height: 24px;
`,
);

const CenteredLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
`;
interface LinkSectionProps extends LinkProps {
  color?: LinkTextColor;
}
export const LinkSection = ({
  children,
  color,
  ...linkProps
}: LinkSectionProps) => (
  <CenteredLink {...linkProps}>
    <LinkSectionBody color={color ?? "blueLight"}>{children}</LinkSectionBody>
    <img src={linkIcon} alt="go to" />
  </CenteredLink>
);
