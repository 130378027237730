import { Address, Contact } from "apps-common/types";

// must be home address not billing address for checking tax in making PM
export const isExistingStateValid = (
  homeCountry: string | undefined,
  homeState: string | undefined | null,
) => {
  const stateRequiredCountries = ["US", "CA", "AE", "JP"];
  const isStateRequiredCountry = stateRequiredCountries.includes(
    homeCountry ?? "",
  );
  return isStateRequiredCountry ? !!homeState : true;
};

// if the existing address is manually verified but missing state ex) AE, then the user needs to contact MX to update the address
export const canUseExistingAddressForPM = (
  homeAddress: Address | Contact | null,
) => {
  return (
    homeAddress && isExistingStateValid(homeAddress.country, homeAddress.state)
  );
};
