import { useMemo } from "react";

import { useIsDesktopView } from "../useIsDesktopView";

import { DesktopFooter } from "./DesktopFooter";
import { MobileFooter } from "./MobileFooter";
import { FooterProps } from "./types";

export const Footer = (props: FooterProps) => {
  const [isDesktopView] = useIsDesktopView();

  const footerComponent = useMemo(() => {
    if (isDesktopView) {
      return <DesktopFooter {...props} />;
    }
    return <MobileFooter {...props} />;
  }, [isDesktopView, props]);

  return footerComponent;
};
