import { useRef, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { passwordRegex } from "common-utils";
import { UpdatePasswordRequestBody } from "oura-account-api-client";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { styled } from "styled-components";
import { t } from "translations";

import { updatePassword } from "apps-common/hooks/AccountApi";
import {
  Button,
  CTALoader,
  ErrorBanner,
  Form,
  PasswordField,
  SubmitButton,
} from "ui";
import { Link, SectionTitle, SmallText } from "ui/styles/text";

import { routes } from "../../routes";
import { PasswordSuccessDialog } from "../PasswordSuccessDialog";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Title = styled(SectionTitle)`
  margin: 0 10px 20px;
`;

const StyledForm = styled(Form)`
  gap: 0;

  ${Button}[type="submit"] {
    margin-top: 40px;
    margin-bottom: 0;
  }

  & > div:first-child {
    margin-bottom: 8px;
  }
`;

type FormValues = UpdatePasswordRequestBody & {
  serverError?: string;
};

export const UpdatePasswordForm = () => {
  const { clearErrors, formState, handleSubmit, register, setError } =
    useForm<FormValues>({
      criteriaMode: "all",
      mode: "onTouched",
    });
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const pwDialogRef = useRef<HTMLDialogElement>(null);

  const passwordMutation = useMutation({
    mutationFn: updatePassword,
  });

  const onClose = () => {
    pwDialogRef.current?.close();
  };

  const onSubmit = (data: UpdatePasswordRequestBody) => {
    passwordMutation.mutate(data, {
      onSuccess: () => pwDialogRef.current?.showModal(),
      onError: (error) => {
        const message =
          error.message === "InvalidPassword"
            ? intl.formatMessage({ id: "membership_hub_invalid_password" })
            : error.message;
        setError("serverError", { type: "server", message });
      },
    });
  };

  const TITLE = t("membership_hub_update_password");

  return (
    <Container>
      {formState.errors.serverError && (
        <ErrorBanner marginBottom="8px">
          {formState.errors.serverError.message}
        </ErrorBanner>
      )}
      <Title>{TITLE}</Title>

      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        onFocus={() => {
          clearErrors("serverError");
        }}
      >
        <PasswordField
          data-testid="currentPasswordInput"
          showIcon={false}
          showPassword={showPassword}
          onRevealIconClick={() => {
            setShowPassword(!showPassword);
          }}
          placeholder={intl.formatMessage({
            id: "membership_hub_enter_current_password",
          })}
          {...register("password", {
            required: {
              value: true,
              message: intl.formatMessage({
                id: "membership_hub_password_required_error_message",
              }),
            },
          })}
          error={formState.errors.password?.message}
        />
        <Link
          $color="helsinkiBlue"
          $fontSize="12px"
          $fontWeight="700"
          $textAlign="right"
          href={routes.requestPasswordReset}
        >
          {t("membership_hub_forgot_password")}
        </Link>
        <PasswordField
          data-testid="newPasswordInput"
          showIcon={false}
          showPassword={showNewPassword}
          onRevealIconClick={() => {
            setShowNewPassword(!showNewPassword);
          }}
          placeholder={intl.formatMessage({
            id: "membership_hub_enter_new_password",
          })}
          {...register("newPassword", {
            required: {
              value: true,
              message: intl.formatMessage({
                id: "membership_hub_password_required_error_message",
              }),
            },
            pattern: {
              value: passwordRegex,
              message: intl.formatMessage({
                id: "membership_error_password_requirements",
              }),
            },
          })}
          error={formState.errors.newPassword?.message}
        />
        <SmallText $fontSize="xsmall" $margin="10px">
          {t("membership_hub_pw_creation_requirement_policy")}
        </SmallText>
        <SubmitButton
          disabled={!formState.isValid || passwordMutation.isPending}
        >
          {passwordMutation.isPending ? <CTALoader /> : TITLE}
        </SubmitButton>
      </StyledForm>
      <PasswordSuccessDialog dialogRef={pwDialogRef} onClose={onClose} />
    </Container>
  );
};
