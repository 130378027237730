import { gql } from "graphql-request";

import { PaymentSignature } from "../types";
import { axiosQuery } from "../utils/axiosQuery";

const MUTATION = gql`
  mutation PaymentSignature($pageId: ID!) {
    paymentSignature(pageId: $pageId) {
      key
      signature
      tenantId
      token
    }
  }
`;

export interface PaymentSignatureInput {
  pageId: string;
}

interface PaymentSignatureResponse {
  paymentSignature: PaymentSignature;
}

export const paymentSignature = (vars: PaymentSignatureInput) =>
  axiosQuery<PaymentSignatureResponse>("PaymentSignature", MUTATION, vars);
