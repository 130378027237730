import { gql } from "graphql-request";

import { BillingPeriod } from "apps-common/types";
import { axiosQuery } from "apps-common/utils/axiosQuery";
import { logger } from "apps-common/utils/logger";

import { isStrictNever } from "../utils/isStrictNever";
import {
  GatewayError,
  GatweayErrorCode,
  SupaApiError,
} from "../utils/SupaError";

interface ApiRenewSubscriptionSuccess {
  __typename: "ApiRenewSubscriptionSuccess";
  subscriptionId: string;
}
interface ApiRenewLimitReachedResponse {
  __typename: "ApiRenewLimitReachedResponse";
  renewCount: number;
}
interface ApiPaymentGatewayFailedResponse {
  __typename: "ApiPaymentGatewayFailedResponse";
  errorGroup: GatweayErrorCode;
  isRetryable: boolean;
  message: string;
}

interface RenewSubscriptionResponse {
  renewMembershipSubscription:
    | ApiRenewSubscriptionSuccess
    | ApiRenewLimitReachedResponse
    | ApiPaymentGatewayFailedResponse;
}

const RENEW_SUBSCRIPTION_MUTATION = gql`
  mutation RenewSubscription($billingPeriod: ApiBillingPeriod!) {
    renewMembershipSubscription(billingPeriod: $billingPeriod) {
      ... on ApiRenewSubscriptionSuccess {
        __typename
        subscriptionId
      }
      ... on ApiRenewLimitReachedResponse {
        __typename
        renewCount
      }
      ... on ApiPaymentGatewayFailedResponse {
        __typename
        errorGroup
        isRetryable
        message
      }
    }
  }
`;

export const renewSubscription = async (
  billingPeriod: BillingPeriod,
): Promise<ApiRenewSubscriptionSuccess> => {
  try {
    const { renewMembershipSubscription } =
      await axiosQuery<RenewSubscriptionResponse>(
        "RenewSubscription",
        RENEW_SUBSCRIPTION_MUTATION,
        { billingPeriod },
      );

    switch (renewMembershipSubscription.__typename) {
      case "ApiRenewSubscriptionSuccess": {
        return renewMembershipSubscription;
      }
      case "ApiRenewLimitReachedResponse": {
        throw new SupaApiError(
          `Account already has renewed the maximum of ${renewMembershipSubscription.renewCount} times. Renew is blocked.`,
          "RENEW_LIMIT_REACHED",
        );
      }
      case "ApiPaymentGatewayFailedResponse": {
        throw new GatewayError(
          renewMembershipSubscription.message,
          renewMembershipSubscription.errorGroup,
          renewMembershipSubscription.isRetryable,
        );
      }

      default: {
        isStrictNever(renewMembershipSubscription);
      }
    }
  } catch (error) {
    logger.error(`Error occurred while renewing`, { error });
    throw error;
  }
};
