import { useIntl } from "react-intl";

import { track } from "apps-common/utils/analytics";

import { NavigationRow, SectionCard } from "./SectionCard";

export const ViewOrderStatus = () => {
  const intl = useIntl();
  return (
    <SectionCard>
      <NavigationRow
        target="_blank"
        label={intl.formatMessage({ id: "membership_hub_view_order_status" })}
        href="https://ouraring.com/my-account"
        onClick={() => {
          track({
            event: "Link Clicked",
            payload: {
              cta: "oura_order_status_my_account",
              location: "body",
            },
          });
        }}
      />
    </SectionCard>
  );
};
