import { MessageKey } from "translations";

const ZUORA_ERROR_CODES: Record<string, MessageKey> = {
  Attempt_Exceed_Limitation: "checkout_payment_max_attempts",
  BusinessValidationError: "checkout_payment_generic_error",
  Decryption_Error: "checkout_payment_generic_error",
  ExceededMaxLength: "checkout_payment_generic_error",
  GatewayTransactionError: "checkout_payment_generic_error",
  GeneralSystemError: "checkout_payment_generic_error",
  HostedPageFieldValidationError: "checkout_payment_generic_error",
  Invalid_PaymentGateway: "checkout_payment_generic_error",
  Invalid_Request_Method: "checkout_payment_generic_error",
  Invalid_Security: "checkout_payment_generic_error",
  InvalidFormat: "checkout_payment_invalid_card_error",
  NullValue: "checkout_payment_generic_error",
  ReCaptcha_Validation_Failed: "checkout_payment_captcha_error",
  Submit_Too_Quick: "checkout_payment_max_attempts",
  Validate_Dynamic_Params_Failed: "checkout_payment_generic_error",
  ThreeDs2_Authentication_Exception: "checkout_payment_3ds_error",
};

const ZUORA_ERROR_MESSAGES: Record<string, MessageKey> = {
  "Transaction declined.1000 - Gateway Rejected: avs":
    "checkout_payment_avs_error",
  "Transaction declined.GATEWAY_REJECTED - Gateway Rejected: risk_threshold":
    "checkout_payment_max_attempts",
  "Transaction declined.2014 - Processor Declined - Fraud Suspected":
    "checkout_payment_fraud_suspected",
  "Transaction declined.GATEWAY_REJECTED - Authentication failed, please check your password.":
    "checkout_payment_authentication_error",
  "Transaction declined.2038 - Processor Declined":
    "checkout_payment_bank_rejected",
  "Transaction declined.z_verify_card_failed - Verify card failed.":
    "checkout_payment_bank_rejected",
  "Transaction declined.2010 - Card Issuer Declined CVV":
    "checkout_payment_bank_rejected",
  "Transaction declined.2057 - Issuer or Cardholder has put a restriction on the card":
    "checkout_payment_bank_rejected",
  "Transaction declined.2044 - Declined - Call Issuer":
    "checkout_payment_bank_rejected",
  "Transaction declined.2001 - Insufficient Funds":
    "checkout_payment_funds_error",
  "Transaction declined.2047 - Call Issuer. Pick Up Card.":
    "checkout_payment_bank_rejected",
  "Transaction declined.2015 - Transaction Not Allowed":
    "checkout_payment_bank_rejected",
  "Transaction declined.2002 - Limit Exceeded":
    "checkout_payment_bank_rejected",
  "Transaction declined.TRANSACTION_PAYMENT_ - Unknown or expired payment_method_nonce.":
    "checkout_payment_bank_rejected",
  "Transaction declined.2000 - Do Not Honor": "checkout_payment_bank_rejected",
  "Transaction declined.2007 - No Account": "checkout_payment_bank_rejected",
  "Transaction declined.TRANSACTION_THREE_D_ - Merchant account does not support 3D Secure transactions for card type.":
    "checkout_payment_bank_rejected",
  "User has insufficient funds to complete the purchase":
    "checkout_payment_funds_error",
};

const GENERAL_ERROR_MESSAGE = "checkout_payment_generic_error";

export const getZuoraErrorMessageKey = (
  code: string,
  message?: string,
): MessageKey => {
  if (message && ZUORA_ERROR_MESSAGES[message]) {
    return ZUORA_ERROR_MESSAGES[message];
  } else if (code && ZUORA_ERROR_CODES[code]) {
    return ZUORA_ERROR_CODES[code];
  } else {
    return GENERAL_ERROR_MESSAGE;
  }
};
