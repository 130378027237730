import { gql } from "graphql-request";

import { AddressSlice, MemberSlice } from "apps-common/store";
import { axiosQuery } from "apps-common/utils/axiosQuery";
import { throwError } from "apps-common/utils/errorHandler";
import { logger } from "apps-common/utils/logger";

interface UpdateAccountResponse {
  paymentMethodId: string;
}

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccount($account: ApiAccountInput!) {
    updateAccount(account: $account) {
      paymentMethodId
    }
  }
`;

export const updateAccount = async (
  paymentMethodId: string,
  paymentGateway: string,
  userAddressForm: AddressSlice["userAddressForm"],
  email: MemberSlice["email"],
  currency: string,
): Promise<UpdateAccountResponse> => {
  const billToContact = {
    ...userAddressForm?.billingAddress,
    personalEmail: email,
  };

  const shipToContact = {
    ...userAddressForm?.shippingAddress,
    personalEmail: email,
  };

  const updateAccountInput = {
    paymentMethodId,
    paymentGatewayName: paymentGateway,
    billToContact,
    shipToContact,
    currency,
  };

  return await axiosQuery<UpdateAccountResponse>(
    "UpdateAccount",
    UPDATE_ACCOUNT_MUTATION,
    {
      account: updateAccountInput,
    },
  ).catch((error) => {
    logger.error("Error occurred while updating account", { error });
    throw throwError("errorOnAccountUpdate", error);
  });
};
