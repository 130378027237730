import { useEffect, useRef } from "react";

import { UseFormClearErrors, UseFormSetValue, useForm } from "react-hook-form";
import { t } from "translations/src/LocaleContext";

import { Dialog } from "ui/components/Dialog";

import { Address, Problem } from "../types";

import { FormValues } from "./AddressForm/types";
import { AddressSuggestionSelector } from "./AddressSuggestionSelector";

interface AddressSuggestionModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  currentAddress: Address;
  setAddressFormData: UseFormSetValue<FormValues>;
  problems: Problem[];
  clearErrors: UseFormClearErrors<FormValues>;
}

export const AddressSuggestionModal = ({
  showModal,
  setShowModal,
  currentAddress,
  setAddressFormData,
  problems,
  clearErrors,
}: AddressSuggestionModalProps) => {
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (showModal) {
      ref.current?.showModal();
    }
  }, [showModal]);

  const selectAddress = () => {
    if (getValues("selectedAddress") === "suggested") {
      const correctedAddress = getCorrectAddress(problems, currentAddress);
      setAddressFormData("billingAddress", correctedAddress, {
        shouldValidate: true,
      });
    }
    clearErrors("serverError");
    ref.current?.close();
    setShowModal(false);
  };
  const onClose = () => {
    ref.current?.close();
    setShowModal(false);
  };
  const { getValues, register } = useForm<{
    selectedAddress: string;
  }>({
    values: {
      selectedAddress: "entered",
    },
  });
  const getCorrectAddress = (problems: Problem[], address: Address) => {
    const correctAddress: Address = { ...address };
    problems.forEach((problem) => {
      if (problem.field === "address1") {
        correctAddress.address1 = problem.correctedValue ?? address.address1;
      } else if (problem.field === "city") {
        correctAddress.city = problem.correctedValue ?? address.city;
      } else if (problem.field === "state") {
        correctAddress.state = problem.correctedValue ?? address.state;
      } else if (problem.field === "postalCode") {
        correctAddress.postalCode =
          problem.correctedValue ?? address.postalCode;
      }
    });
    return correctAddress;
  };
  return (
    <Dialog
      ref={ref}
      icon={null}
      title={t("membership_hub_address_validation_suggestion_header")}
      textContents={[t("membership_hub_address_validation_suggestion_text")]}
      primaryButton={{
        text: t(
          "membership_hub_address_validation_suggestion_use_selected_address",
        ),
        onClick: selectAddress,
      }}
      secondaryButton={{
        text: t(
          "membership_hub_address_validation_suggestion_edit_entered_address",
        ),
        onClick: onClose,
      }}
    >
      <AddressSuggestionSelector
        currentAddress={currentAddress}
        correctedAddress={getCorrectAddress(problems, currentAddress)}
        problems={problems}
        {...register("selectedAddress", { required: true })}
      />
    </Dialog>
  );
};
