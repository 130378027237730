import { styled } from "styled-components";

const PaginationContainer = styled.div(
  ({ theme }) => `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 24px 0 0 0;
  .pageCard {
    height: 40px;
    padding: 0 8px;
    background: transparent;
    border: 0px;
    font-size: ${theme.fontSizes.medium};
    font-weight: 600;
    box-sizing: border-box;
    color: ${theme.colors.white};
    cursor: pointer;
  }
  .pageCard.active {
    color: ${theme.colors.blueLight};
  }
  .pageCard:disabled {
    color: ${theme.colors.grayLight}
  }
`,
);

const PageButton = styled.button<{ $active?: boolean }>(
  ({ theme, $active }) => `
    height: 40px;
    padding: 0 8px;
    background: transparent;
    border: 0px;
    font-size: ${theme.fontSizes.medium};
    font-weight: 600;
    box-sizing: border-box;
    color: ${$active ? theme.colors.blueLight : theme.colors.white};
    cursor: pointer;
     &[disabled] {
      color: ${theme.colors.grayLight}
    }
`,
);

/*
TODO: Waiting for design clarification how dynamic behaviour should work
const MorePaginationIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 40px;
`;*/

interface PaginationProps {
  currentPage: number;
  pageCount: number;
  setCurrentPage: (prevPage: number) => void;
}

export const Pagination = ({
  currentPage,
  pageCount,
  setCurrentPage,
}: PaginationProps) => {
  const renderPageButtons = () => {
    const results = [];
    for (let i = 1; i < pageCount + 1; i++) {
      results.push(
        <PageButton
          key={i}
          $active={currentPage === i}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </PageButton>,
      );
    }
    return results;
  };
  return (
    <PaginationContainer>
      <PageButton
        onClick={() => {
          if (currentPage > 1) setCurrentPage(currentPage - 1);
        }}
        disabled={currentPage <= 1}
      >{`<`}</PageButton>
      {renderPageButtons()}
      <PageButton
        onClick={() => {
          if (currentPage < pageCount) setCurrentPage(currentPage + 1);
        }}
        disabled={currentPage === pageCount}
      >{`>`}</PageButton>
    </PaginationContainer>
  );
};
