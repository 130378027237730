import { PropsWithChildren, ReactNode } from "react";

import { FormattedMessage, IntlProvider, ReactIntlErrorCode } from "react-intl";

import { MESSAGES, MessageKey } from "./setup";

export const LOCALES_SUPPORTED = [
  "de",
  "en",
  "es",
  "fi",
  "fr",
  "it",
  "ja",
] as const;
export const LOCALE_DEFAULT = "en";

export type LocaleSupported = (typeof LOCALES_SUPPORTED)[number];

export function isSupportedLocale(locale: string): locale is LocaleSupported {
  return LOCALES_SUPPORTED.includes(locale as LocaleSupported);
}

export const getMessagesForLocale = (locale: LocaleSupported) =>
  MESSAGES[locale];

export const getLocale = (): LocaleSupported => {
  // Dig out the locale from accept-language header, then extract 2 char language code out of it
  const browserLocale = window.navigator.language ?? "en-US";
  const locale = new Intl.Locale(browserLocale).language;

  // Fall back to en if not supported language found
  if (!isSupportedLocale(locale)) {
    return LOCALE_DEFAULT;
  }
  return locale;
};

export function LocaleProvider({ children }: PropsWithChildren) {
  const locale = getLocale();
  const messages = getMessagesForLocale(locale);

  return (
    <IntlProvider
      onError={(err) => {
        if (
          err.code === ReactIntlErrorCode.MISSING_TRANSLATION ||
          err.code === ReactIntlErrorCode.MISSING_DATA
        ) {
          throw new Error("Error:" + err.message);
        }
        throw err;
      }}
      messages={messages}
      locale={locale}
      defaultLocale={LOCALE_DEFAULT}
    >
      {children}
    </IntlProvider>
  );
}

/**
 * This is built over React-Intl's <FormattedMessage /> for convenience
 * @example
 * t("guide_infotext_sleep_score", { score })
 */
export function t(
  id: MessageKey,
  values?: Record<string, ReactNode | ((chunks: ReactNode) => ReactNode)>,
) {
  return <FormattedMessage id={id} values={values} />;
}
