import isEmpty from "lodash/isEmpty";
import { useIntl } from "react-intl";
import { styled } from "styled-components";
import { t } from "translations";

import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { identify, track } from "apps-common/utils/analytics";
import { throwError } from "apps-common/utils/errorHandler";
import { upperCaseFirstLetter } from "apps-common/utils/helpers";
import { Loader, Header, Card, Button } from "ui";
import { useIsDesktopView } from "ui/components/useIsDesktopView";
import { MainContainer } from "ui/styles/containers";
import { media } from "ui/themes/signup";

import { AccountManagement } from "../components/AccountManagement";
import { BillingInformation } from "../components/BillingInformation";
import { CancelSubscription } from "../components/CancelSubscription";
import { ViewOrderStatus } from "../components/ViewOrderStatus";
import { routes } from "../routes";
import { useStore } from "../store";
import {
  isGucciRingUser,
  isB2CMember,
  isLegacyMember,
  isExpiredSubscription,
  isActiveSubscription,
} from "../utils/member";
import { getCurrentPaymentMethod } from "../utils/paymentMethod";

const TwoColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.large`
    flex-direction: row;
    justify-content: space-between;

    > :first-child {
      flex: 0 0 34%;
    }

    > :nth-child(2) {
      flex: 0 0 64%;
    }

    section:first-child > h3 {
        margin-top: 0;
      }
  `}
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.large`
    align-items: flex-start;
  `}
`;

export const MembershipHubPage = () => {
  const [isDesktopView] = useIsDesktopView("large");
  const intl = useIntl();
  const setEmail = useStore((state) => state.setEmail);
  const {
    data: accountData,
    error: errorInGetAccount,
    isError: isErrorInGetAccount,
    isFetching: isFetchingAccountData,
    refetch: refetchAccountData,
  } = useGetAccount();

  if (isFetchingAccountData) {
    return <Loader />;
  }

  if (isErrorInGetAccount || !accountData) {
    throw throwError("errorOnGetAccount", errorInGetAccount);
  }

  const {
    account: { email, membershipType, currentSubscription },
    membershipOffering: { prepaidMonths: pendingPrepaidMonths },
  } = accountData;

  const prepaidMonths =
    (currentSubscription?.prepaidPeriods?.current?.lengthInMonths ?? 0) +
    (currentSubscription?.prepaidPeriods?.future?.lengthInMonths ?? 0);

  identify({
    traits: {
      email,
      prepaidMonths,
    },
  });
  setEmail(email);

  // null membershipType could mean signup flow dropped out users or something wrong with their account. we will know if their membershipType is still null after completing the signup flow
  if (!membershipType) {
    throw throwError("errorOnB2CNoSubNoAccessOrNullMembershipType");
  }

  const Gen2View = (
    <>
      <Header appType="hub" pageType="create"></Header>
      <MainContainer>
        <TwoColumnContainer>
          <Card
            title={intl.formatMessage({
              id: "membership_hub_introducing_oura_ring_gen3",
            })}
            description={intl.formatMessage({
              id: "membership_hub_introducing_oura_ring_gen3_body",
            })}
            backgroundImage="period-prediction-onboarding-2.webp"
            size="tall"
            ariaLabel="Gen3 Ring"
          />

          <Column>
            {isDesktopView ? (
              <>
                <AccountManagement email={email} />
                <Button
                  $margin="30px 0 24px 0"
                  onClick={() => window.open("https://ouraring.com", "_blank")}
                >
                  {t("membership_hub_upgrade_to_gen3")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  $margin="30px 0 24px 0"
                  onClick={() => {
                    track({
                      event: "Link Clicked",
                      payload: {
                        cta: "upgrade_to_gen3",
                        location: "body",
                      },
                    });
                    window.location.replace("https://ouraring.com");
                  }}
                >
                  {t("membership_hub_upgrade_to_gen3")}
                </Button>
                <AccountManagement email={email} />
              </>
            )}
          </Column>
        </TwoColumnContainer>
      </MainContainer>
    </>
  );
  if (isLegacyMember(membershipType)) {
    return Gen2View;
  }

  const {
    shipToContact,
    billToContact,
    subscriptions,
    paymentMethods,
    createdAt,
    isValidCurrency,
  } = accountData.account;
  const hasAddress = !!(shipToContact && billToContact);

  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throw throwError("hubGeneralError", "No subscription found for user.");
  }

  const { nextBillingDate, subscriptionState, pendingCancellation } =
    currentSubscription;

  const currentPaymentMethod = getCurrentPaymentMethod(paymentMethods);

  const getFirstName = () => {
    const firstName = shipToContact?.firstName;
    if (!firstName || isGucciRingUser(firstName)) {
      return upperCaseFirstLetter(
        intl.formatMessage({ id: "membership_signup_fallback_name" }),
      );
    }

    return upperCaseFirstLetter(firstName);
  };

  const TITLE = t("membership_hub_welcome", { name: getFirstName() });
  const dunningLevel = accountData.account.dunningLevel ?? 0;

  const isActiveB2C =
    isB2CMember(membershipType) &&
    isActiveSubscription(subscriptionState) &&
    !pendingCancellation;

  const showViewOrderStatus =
    isB2CMember(membershipType) && !isEmpty(subscriptions);

  const membershipExperienceCard = (
    <Card
      title={intl.formatMessage({
        id: "membership_hub_member_experience",
      })}
      description={intl.formatMessage({
        id: "membership_hub_member_experience_sub_title",
      })}
      onClick={() => {
        track({
          event: "Link Clicked",
          payload: {
            cta: "member_experience",
            location: "body",
          },
        });
      }}
      ariaLabel={intl.formatMessage(
        { id: "membership_hub_accessibility_navigate_to" },
        { link: "member experience" },
      )}
      href={routes.memberExperience}
      backgroundImage="ringimage-membershiphub.webp"
    />
  );

  return (
    <>
      <Header
        appType="hub"
        pageType="hubHome"
        title={TITLE}
        createdAt={createdAt ?? ""}
      ></Header>
      <MainContainer>
        <TwoColumnContainer>
          <div>{isDesktopView && membershipExperienceCard}</div>
          <div>
            {!isDesktopView &&
              !isExpiredSubscription(subscriptionState) &&
              !pendingCancellation &&
              membershipExperienceCard}
            <BillingInformation
              paymentMethod={currentPaymentMethod}
              currentSubscription={currentSubscription}
              dunningLevel={dunningLevel}
              membershipType={membershipType}
              hasAddress={hasAddress}
              pendingPrepaidMonths={pendingPrepaidMonths}
              isValidCurrency={isValidCurrency}
            />
            {!isDesktopView &&
              (isExpiredSubscription(subscriptionState) ||
                pendingCancellation) &&
              membershipExperienceCard}
            <AccountManagement email={email} />
            {showViewOrderStatus && <ViewOrderStatus />}
            {isActiveB2C && (
              <CancelSubscription
                nextBillingDate={nextBillingDate}
                refetchAccountData={refetchAccountData}
              />
            )}
          </div>
        </TwoColumnContainer>
      </MainContainer>
    </>
  );
};
