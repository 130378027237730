import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { t } from "translations";

import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { Channel, BillingPeriod } from "apps-common/types";
import { throwError } from "apps-common/utils/errorHandler";
import { periodToProductPriceKey } from "apps-common/utils/productLocalization";
import { Header, VerticalCard, Loader } from "ui";
import { StyledCheckIcon } from "ui/components/Icons/Check";
import { StyledPromotionsIcon } from "ui/components/Icons/Promotions";
import { StyledSelfImprovementIcon } from "ui/components/Icons/SelfImprovement";
import { StyledUpdatesIcon } from "ui/components/Icons/Updates";
import { MainContainer } from "ui/styles/containers";
import { HeaderText } from "ui/styles/text";
import { media } from "ui/themes/signup";

import { FeatureText } from "../components/FeatureText";
import { routes } from "../routes";
import { isEntitlementMember } from "../utils/member";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  ${media.large`
    justify-content: space-between;
    flex-wrap:nowrap;
  `}
`;
const FeatureContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > :first-child {
    padding-top: 0;
  }

  ${media.large`
    justify-content: space-between;
    flex-wrap:wrap;
    margin-left: 120px
  `}
`;
const TextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 40px 0;

  ${media.large`
    justify-content: space-between;
    flex-wrap:nowrap;
    padding-bottom: 80px;
    padding-top: 76px;
  `};
`;
export const MembershipExperiencePage = () => {
  const navigate = useNavigate();
  const {
    data: accountData,
    error: errorInGetAccount,
    isFetching: isFetchingAccountData,
  } = useGetAccount();
  if (isFetchingAccountData || !accountData) {
    return <Loader />;
  }

  if (errorInGetAccount) {
    throwError("errorOnGetAccount", errorInGetAccount);
  }

  const membershipType = accountData.account.membershipType;
  // null membershipType could mean signup flow dropped out users or something wrong with their account. we will know if their membershipType is still null after completing the signup flow
  if (!membershipType) {
    throw throwError("errorOnB2CNoSubNoAccessOrNullMembershipType");
  }

  const currentSubscription = accountData.account.currentSubscription;

  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throw throwError("hubGeneralError", "No subscription found for user.");
  }

  // not all users have recurringFee, don't remove the "?" from recurringFee

  const amount = currentSubscription.recurringFee?.amount ?? 5.99;
  const currency = currentSubscription.recurringFee?.currency ?? "USD";

  const localisedPrice = amount.toLocaleString(undefined, {
    style: "currency",
    currency: currency,
  });

  const priceToShow = `${localisedPrice} ${currency}`;

  const price_text = t(
    periodToProductPriceKey(
      currentSubscription.recurringFee?.billingPeriod ?? BillingPeriod.Months,
    ),
    {
      subscription_price: priceToShow,
    },
  );
  const b2cMemberText = t("membership_hub_experience_subtitle", {
    subscription_price: price_text,
  });
  const entilementMemberText = t(
    "membership_hub_entitlment_user_member_experience_page_subtitle",
  );

  // TODO might need to change how userType is derived here (atm backend returns membershipType as B2C for InActive B2B)
  const SUBTITLE_PRICE_TEXT =
    isEntitlementMember(membershipType) ||
    currentSubscription.channel === Channel.b2b ||
    !currentSubscription.recurringFee
      ? entilementMemberText
      : b2cMemberText;
  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t("membership_hub_experience_title")}
        onBackClick={() => navigate(routes.index)}
        subTitle={SUBTITLE_PRICE_TEXT}
      />
      <MainContainer>
        <CardContainer>
          <VerticalCard
            src={"card-bg-indepth/legacy.webp"}
            alt={"Indepth card"}
            text={t("membership_hub_experience_card_indepth")}
            srcset={
              "card-bg-indepth/480w.webp 480w,card-bg-indepth/640w.webp 640w,card-bg-indepth/768w.webp 768w,card-bg-indepth/960w.webp 960w,card-bg-indepth/1024w.webp 1024w,card-bg-indepth/1366w.webp 1366w,card-bg-indepth/1600w.webp 1600w,card-bg-indepth/1920w.webp 1920w"
            }
            sizes={"(min-width: 1024px) 50vw, 100vw"}
          />
          <VerticalCard
            src={"card-bg-personalized/legacy.webp"}
            alt={"Personalized card"}
            text={t("membership_hub_experience_card_personalized")}
            srcset={
              "card-bg-personalized/480w.webp 480w,card-bg-personalized/640w.webp 640w,card-bg-personalized/768w.webp 768w,card-bg-personalized/960w.webp 960w,card-bg-personalized/1024w.webp 1024w,card-bg-personalized/1366w.webp 1366w,card-bg-personalized/1600w.webp 1600w,card-bg-personalized/1920w.webp 1920w"
            }
            sizes={"(min-width: 1024px) 50vw, 100vw"}
          />
          <VerticalCard
            src={"card-bg-bodytemp/legacy.webp"}
            alt={"Bodytemp card"}
            text={t("membership_hub_experience_card_body_temp")}
            srcset={
              "card-bg-bodytemp/480w.webp 480w,card-bg-bodytemp/640w.webp 640w,card-bg-bodytemp/768w.webp 768w,card-bg-bodytemp/960w.webp 960w,card-bg-bodytemp/1024w.webp 1024w,card-bg-bodytemp/1366w.webp 1366w,card-bg-bodytemp/1600w.webp 1600w,card-bg-bodytemp/1920w.webp 1920w"
            }
            sizes={"(min-width: 1024px) 50vw, 100vw"}
          />
          <VerticalCard
            src={"card-bg-livehr/legacy.webp"}
            alt={"Live HR card"}
            text={t("membership_hub_experience_card_heart_rate")}
            srcset={
              "card-bg-livehr/480w.webp 480w,card-bg-livehr/640w.webp 640w,card-bg-livehr/768w.webp 768w,card-bg-livehr/960w.webp 960w,card-bg-livehr/1024w.webp 1024w,card-bg-livehr/1366w.webp 1366w,card-bg-livehr/1600w.webp 1600w,card-bg-livehr/1920w.webp 1920w"
            }
            sizes={"(min-width: 1024px) 50vw, 100vw"}
          />
        </CardContainer>
        <TextContainer>
          <HeaderText
            $fontSize={"xxlarge"}
            $fontWeight={"300"}
            $lineHeight={"42px"}
          >
            {t("membership_hub_experience_title_body")}
          </HeaderText>
          <FeatureContainer>
            <FeatureText
              header={t("membership_hub_experience_subtitle_features")}
              text={t("membership_hub_experience_text_features")}
              icon={<StyledCheckIcon />}
            />
            <FeatureText
              header={t(
                "membership_hub_experience_subtitle_latest_and_greatest",
              )}
              text={t("membership_hub_experience_text_latest_and_greatest")}
              icon={<StyledUpdatesIcon />}
            />
            <FeatureText
              header={t("membership_hub_experience_subtitle_insights")}
              text={t("membership_hub_experience_text_insights")}
              icon={<StyledSelfImprovementIcon />}
            />
            <FeatureText
              header={t(
                "membership_hub_experience_subtitle_exclusive_promotions",
              )}
              text={t("membership_hub_experience_text_exclusive_promotions")}
              icon={<StyledPromotionsIcon />}
            />
          </FeatureContainer>
        </TextContainer>
      </MainContainer>
    </>
  );
};
