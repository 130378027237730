/*
  This page is rendered inside the Zuora iFrame, as path '/payment-method/card-callback',
  with QueryParameters:
{
  success: boolean
  responseFrom: Response_From_Submit_Page
  token: string
  signature: string
  tenantId: string
  refId?: string
  errorCode?: string
  errorMessage?: string
}
*/

import { useEffect } from "react";

import { Loader } from "ui";

import { useQueryParams } from "../../hooks/useQueryParams";
import { logger } from "../../utils/logger";

import { CallbackError } from "./CallbackError";

import type { OnErrorCallback, OnSuccessCallback } from "./index";

const isCallbackSuccess = (qp: URLSearchParams): boolean => {
  const isResponseFromSubmit =
    qp.get("responseFrom") === "Response_From_Submit_Page";
  const isSuccess = qp.get("success") === "true";
  const hasRefId = Boolean(qp.get("refId"));

  return isResponseFromSubmit && isSuccess && hasRefId;
};

const callbackHandler = (
  queryParams: URLSearchParams,
  onSuccess: OnSuccessCallback,
  onError: OnErrorCallback,
) => {
  logger.debug("User directed to Zuora card-callback page", {
    queryParams,
  });

  if (isCallbackSuccess(queryParams)) {
    const paymentMethodId = queryParams.get("refId")!;
    const flow = queryParams.get("field_passthrough1") ?? undefined;

    onSuccess(paymentMethodId, flow);
  } else {
    const errorMessage = queryParams.get("errorMessage") ?? "";
    const errorCode = queryParams.get("errorCode") ?? "";

    onError(new CallbackError(errorMessage, errorCode));
  }
};

const useHandleCallback = ({
  onSuccess,
  onError,
}: CreditCardCallbackPageProps) => {
  const queryParams = useQueryParams();
  useEffect(() => {
    callbackHandler(queryParams, onSuccess, onError);
  }, [queryParams, onSuccess, onError]);
};

interface CreditCardCallbackPageProps {
  onSuccess: OnSuccessCallback;
  onError: OnErrorCallback;
}

export const CreditCardCallbackPage = (props: CreditCardCallbackPageProps) => {
  useHandleCallback(props);

  return <Loader />;
};
