import { styled } from "styled-components";

import { Button, Form } from "ui";
import { media } from "ui/themes/signup";

export const StyledForm = styled(Form)`
  min-height: 400px;
  justify-content: space-between;

  ${Button}[type="submit"] {
    margin-bottom: 0;
  }

  ${media.small`
    min-height: fit-content;
    
    ${Button}[type="submit"] {
    align-self: flex-start;
    margin-top: 20px;
  }
  `}
`;
