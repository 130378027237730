import { ReactNode } from "react";

import { styled } from "styled-components";
import { t } from "translations";

import { Ball } from "ui/components/Ball";
import { RowContainer } from "ui/styles/containers";
import { Row } from "ui/styles/layout";

const StatusLabel = styled.div(
  ({ theme }) => `
  font-family: ${theme.fonts};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.colors.white};
  margin-left: 12px;
  `,
);

const StatusValue = styled.div<{
  $color: "lightGreen" | "lightRed";
}>(
  ({ theme, $color }) => `
    font-family: ${theme.fonts};
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: ${theme.colors[$color]};
    line-height: 19.6px;
    margin-top: 4px;
  `,
);

interface StatusRowProps {
  label: ReactNode;
  value: ReactNode;
  color: "lightGreen" | "lightRed";
  isLast?: boolean;
}

export const StatusRow = ({
  label,
  value,
  color,
  isLast = false,
}: StatusRowProps) => {
  return (
    <RowContainer $border={!isLast} $height="min-content" $padding="12px 16px">
      <Row $alignItems="center" $wrap="nowrap">
        <Ball $color={color} />
        <StatusLabel>
          {t("membership_hub_billing_status")}: {label}
        </StatusLabel>
      </Row>
      <StatusValue $color={color}>{value}</StatusValue>
    </RowContainer>
  );
};
