/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import noop from "lodash/noop";

import { HostedPageParams, PrepopulateFields } from "../types";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Z: any;
  }
}

// Not used, for anything ATM. If used, needs to be passed using useCallback to CreditCardForm
export type CallbackFunction = (
  params:
    | {
        success: false;
        responseFrom: "Response_From_Request_Page"; // "Response_From_Submit_Page"
        errorCode: string;
        errorMessage: string;
      }
    | {
        success: true;
        refId: string;
        responseFrom: "Response_From_Request_Page";
      },
) => void;

type ZuoraField =
  | "error" // server-side
  | "creditCardExpirationMonth"
  | "creditCardExpirationYear"
  | "creditCardType"
  | "creditCardNumber"
  | "cardSecurityCode"
  | "creditCardHolderName"
  | "creditCardCountry"
  | "creditCardState"
  | "creditCardAddress1"
  | "creditCardAddress2"
  | "creditCardCity"
  | "creditCardPostalCode"
  | "ipAddress"
  | "phone"
  | "email"
  | "identityNumber";

type ZuoraCode =
  | "001" // The required field is not completed.
  | "002" // The card number is invalid.
  | "003" // The card type is invalid.
  | "004" // The CVV number is invalid.
  | "007" // The end-user doesn't resolve the challenge of Google reCAPTCHA Enterprise Interactive Test version.
  | "unknown"; // All client-side errors other than the five above.

interface ZuoraGWInfo {
  type: string; //Gateway type, such as Orbital
  version: number; //Zuora gateway integration version, such as 1, 2 or 3
  responseCode: number; //Raw gateway response code
  responseMessage: string; //Raw gateway error message
  additionalInfo: string; //	An plain JavaScript object containing additional gateway response information if available, such as AVS/CVS results for Orbital gateway
}

export type ClientErrorMessageCallback = (args: {
  field: ZuoraField;
  errorCode: ZuoraCode;
  errorMessage: string;
  gwInfo?: ZuoraGWInfo;
}) => void;

export type ZuoraEvent =
  | "onSubmit"
  | "onloadCallback"
  | "agreement_checked"
  | "agreement_unchecked"
  | "onCaptchaStateChange"
  | "onCancelMandatePage";

// https://knowledgecenter.zuora.com/Zuora_Payments/Payment_Page_and_Payment_Link/Payment_Pages_2.0/KA_Error_Handling_for_Payment_Pages_2.0/Customize_Error_Messages_for_Payment_Pages_2.0#Render_Payment_Pages_2.0_with_Custom_Error_Handling
export const renderForm = ({
  params,
  prepopulateFields,
  callback = noop,
  clientErrorMessageCallback,
}: {
  params: HostedPageParams;
  prepopulateFields: PrepopulateFields;
  callback?: CallbackFunction;
  clientErrorMessageCallback: ClientErrorMessageCallback;
}) => {
  window.Z.renderWithErrorHandler(
    params,
    prepopulateFields,
    callback,
    (
      key: ZuoraField,
      code: ZuoraCode,
      message: string,
      rawGatewayInfo: ZuoraGWInfo,
    ) => {
      window.Z.sendErrorMessageToHpm(key, message);
      clientErrorMessageCallback({
        field: key,
        errorCode: code,
        errorMessage: message,
        gwInfo: rawGatewayInfo,
      });
    },
  );
};

export const addEventHandler = (event: ZuoraEvent, callback: () => void) => {
  window.Z.setEventHandler(event, callback);
};

export const submitForm = () => {
  window.Z.submit();
};
