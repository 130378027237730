import { gql } from "graphql-request";

import { Currency, PaymentGateways } from "../types";
import { axiosQuery } from "../utils/axiosQuery";

interface GetPaymentGatewaysInput {
  host: string;
  country: string;
  currency: Currency;
}

interface GetPaymentGatewaysResponse {
  paymentGateways: PaymentGateways;
}

const QUERY = gql`
  query GetPaymentGateways(
    $host: String!
    $country: String!
    $currency: String
  ) {
    paymentGateways(host: $host, country: $country, currency: $currency) {
      hostedPageId
      creditCardGatewayName
      paypalGatewayName
      sikaHealthGatewayName
    }
  }
`;

export const getPaymentGateways = (vars: GetPaymentGatewaysInput) =>
  axiosQuery<GetPaymentGatewaysResponse>("GetPaymentGateways", QUERY, vars);
