import { ReactNode } from "react";

import { styled } from "styled-components";

import { Background } from "ui/styles/layout";
import { SectionTitle } from "ui/styles/text";

interface SectionCardProps {
  title?: ReactNode;
  children: ReactNode;
}

interface SectionProps {
  $margin: string;
}

const Section = styled.section<SectionProps>(
  ({ $margin }) => `
  margin: ${$margin};
  overflow: hidden;
  width: 100%;
`,
);

export const SectionCard = ({ title, children }: SectionCardProps) => {
  const sectionMargin = title ? "0" : "14px 0 0 0";
  return (
    <Section $margin={sectionMargin}>
      {title && <SectionTitle $margin="24px 0 8px 16px">{title}</SectionTitle>}
      <Background>{children}</Background>
    </Section>
  );
};
