import { ApiErrorResponse } from "oura-account-api-client";

import { ExtendableError } from "./ExtendableError";

export class AccountApiError extends ExtendableError {
  code: ApiErrorResponse["errorCode"];
  detail: ApiErrorResponse["detail"];

  constructor(
    message: string,
    code: ApiErrorResponse["errorCode"],
    detail: ApiErrorResponse["detail"],
  ) {
    super(message);
    this.code = code;
    this.detail = detail;
    this.name = "AccountApiError"; // https://github.com/vitejs/vite/issues/13727
  }
}
