import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import {
  createAddressSlice,
  createMemberSlice,
  createProductSlice,
  AddressSlice,
  MemberSlice,
  ProductSlice,
  createHubSlice,
  HubSlice,
} from "apps-common/store";

export type HubStore = AddressSlice & MemberSlice & ProductSlice & HubSlice;

export const useStore = create<HubStore>()(
  persist(
    (...a): HubStore => ({
      ...createAddressSlice(...a),
      ...createMemberSlice(...a),
      ...createProductSlice(...a),
      ...createHubSlice(...a),
    }),
    { name: "hub", storage: createJSONStorage(() => sessionStorage) },
  ),
);
