import { styled } from "styled-components";

import { media } from "../themes/signup";

export const BasePage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100vh;
  width: 100%;
  padding: 0 20px;

  ${media.small`
      max-width: 540px;
    `}

  ${media.medium`
      max-width: 720px;
    `}
    
  ${media.large`
      max-width: 960px;
      padding: 0 40px;
  `}
    
  ${media.xlarge`
     max-width: 1140px;
     padding: 0 60px;
  `}

  @media (width >= 1400px) {
    max-width: 1340px;
    padding: 0 100px;
  }
`;
