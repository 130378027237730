import { PropsWithChildren } from "react";

import { styled } from "styled-components";

import { HeroRing, HeroNoRing } from "../styles/media";

import OuraLogo from "./assets/oura-text.svg?react";

const ImageContainer = styled.div`
  padding-left: 24px;
  padding-top: 76px;
`;

const HeroImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(1 1 1 / 60%) 96.88%
  );
`;

interface HeroProps {
  page: "landing" | "error" | "create";
}

export const Hero = ({ page, children }: PropsWithChildren<HeroProps>) => {
  switch (page) {
    case "landing": {
      return (
        <HeroImageContainer>
          <HeroRing src="/oura-ring.webp" alt="Ouraring" />;
          <HeroOverlay />
        </HeroImageContainer>
      );
    }
    case "error": {
      return (
        <HeroNoRing>
          <ImageContainer>
            <OuraLogo height="19px" width="60px" flex-shrink="0" />
          </ImageContainer>
          {children}
        </HeroNoRing>
      );
    }
    case "create": {
      return (
        <HeroNoRing>
          <ImageContainer>
            <OuraLogo height="19px" width="60px" flex-shrink="0" />
          </ImageContainer>
          {children}
        </HeroNoRing>
      );
    }
  }
};
