import { styled } from "styled-components";

import EmailSVG from "../assets/email-icon.svg?react";

interface EmailIconProps {
  $width?: string;
  $height?: string;
}

export const StyledEmailIcon = styled(EmailSVG)<EmailIconProps>(
  ({ $width = "24px", $height = "24px" }) => `
  width: ${$width};
  height: ${$height};
`,
);
