import { useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { t } from "translations";

import { track } from "apps-common/utils/analytics";
import { logger } from "apps-common/utils/logger";
import { Header, Button } from "ui";
import { SuccessIcon } from "ui/components/Icons/Success";
import { MainContainer } from "ui/styles/containers";
import { Paragraph, HeaderText } from "ui/styles/text";

import { routes } from "../routes";

interface UpdatePaymentMethodSuccessProps {
  renew: boolean;
}

export const UpdatePaymentMethodSuccess = (
  props: UpdatePaymentMethodSuccessProps,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    logger.info("UpdatePaymentMethodSuccess", { props });
    track({
      event: "Update Payment Method Success",
      payload: {
        flow: props.renew ? "renew" : "update_payment_method",
      },
    });
  }, [props]);

  return (
    <>
      <Header appType="hub" pageType="create" />
      <MainContainer $padding="100px 0">
        <SuccessIcon />
        <HeaderText $textAlign="center" $fontSize="xxxlarge" $margin="12px 0">
          {props.renew
            ? t("membership_hub_welcome_back")
            : t("membership_hub_payment_method_update_success")}
        </HeaderText>
        <Paragraph
          $textAlign="center"
          $color="grayLightest"
          $margin="0 0 30px 0"
        >
          {props.renew
            ? t("membership_hub_renew_notification_body")
            : t("membership_hub_payment_method_update_success_body_text")}
        </Paragraph>
        <Button
          onClick={() => {
            queryClient.invalidateQueries({ queryKey: ["getPaymentMethods"] });
            navigate(routes.index);
          }}
        >
          {t("membership_hub_continue")}
        </Button>
      </MainContainer>
    </>
  );
};
