import { ReactNode, useEffect } from "react";

import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";

import { GetAccountResponse } from "apps-common/graphql/getAccount";
import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { MembershipType, AccessUserTypes } from "apps-common/types";
import { throwError } from "apps-common/utils/errorHandler";
import { Flags, useFlag } from "apps-common/utils/featureFlags";
import { isStrictNever } from "apps-common/utils/isStrictNever";
import { logger } from "apps-common/utils/logger";
import { Loader } from "ui";

import { routes } from "../routes";
import {
  isActiveSubscription,
  isLegacyMember,
  isPendingSubscription,
} from "../utils/member";

// discriminated union types to always give either one of user list (allowedUsers | excludedUsers) but never give both or none
export type UserAccessProps =
  | { allowedUsers: AccessUserTypes[]; excludedUsers?: never }
  | { excludedUsers: AccessUserTypes[]; allowedUsers?: never };

export interface UserAccessCheckProps {
  userAccessRights: UserAccessProps;
  children: ReactNode;
}

const getUserType = (
  membershipType: MembershipType,
  currentSubscription:
    | GetAccountResponse["account"]["currentSubscription"]
    | undefined,
  paymentMethods: GetAccountResponse["account"]["paymentMethods"] | undefined,
  hasAddress: boolean,
  isValidCurrency: boolean,
): AccessUserTypes => {
  if (isLegacyMember(membershipType)) {
    return AccessUserTypes.LEGACY;
  }

  if (!currentSubscription) {
    return AccessUserTypes.UNFINISHED;
  }

  const subscriptionState = currentSubscription.subscriptionState;

  switch (membershipType) {
    case MembershipType.LIFETIME: {
      return AccessUserTypes.LIFETIME;
    }

    case MembershipType.UNFINISHED: {
      return AccessUserTypes.UNFINISHED;
    }

    case MembershipType.UNVERIFIED: {
      return AccessUserTypes.UNVERIFIED;
    }

    case MembershipType.B2C: {
      if (isPendingSubscription(subscriptionState)) {
        return AccessUserTypes.PENDING_B2C;
      }

      // Active subscription
      if (
        isActiveSubscription(subscriptionState) &&
        !currentSubscription.pendingCancellation
      ) {
        if (!isValidCurrency) {
          return AccessUserTypes.INVALID_CURRENCY_ACTIVE_B2C;
        }
        if (!isEmpty(paymentMethods)) {
          return hasAddress
            ? AccessUserTypes.ACTIVE_B2C_WITH_ADDRESS_PM
            : AccessUserTypes.ACTIVE_B2C_NO_ADDRESS;
        }
        return hasAddress
          ? AccessUserTypes.ACTIVE_B2C_NO_PM
          : AccessUserTypes.ACTIVE_B2C_NO_ADDRESS_NO_PM;
      }

      if (currentSubscription.pendingCancellation && !isValidCurrency) {
        return AccessUserTypes.INVALID_CURRENCY_ACTIVE_B2C;
      }

      // Expired or pending cancellation subscription
      if (!isEmpty(paymentMethods)) {
        return hasAddress
          ? AccessUserTypes.INACTIVE_B2C_WITH_ADDRESS_PM
          : AccessUserTypes.INACTIVE_B2C_NO_ADDRESS;
      }
      return hasAddress
        ? AccessUserTypes.INACTIVE_B2C_NO_PM
        : AccessUserTypes.INACTIVE_B2C_NO_ADDRESS_NO_PM;
    }

    case MembershipType.B2B: {
      if (isActiveSubscription(subscriptionState)) {
        return AccessUserTypes.ACTIVE_B2B;
      }
      return AccessUserTypes.INACTIVE_B2B;
    }

    default: {
      return isStrictNever(membershipType);
    }
  }
};

const isAllowedUser = (
  membershipType: MembershipType,
  userAccessRights: UserAccessProps,
  currentSubscription:
    | GetAccountResponse["account"]["currentSubscription"]
    | undefined,
  paymentMethods: GetAccountResponse["account"]["paymentMethods"] | undefined,
  hasAddress: boolean,
  isValidCurrency: boolean,
) => {
  const userType = getUserType(
    membershipType,
    currentSubscription,
    paymentMethods,
    hasAddress,
    isValidCurrency,
  );

  if ("excludedUsers" in userAccessRights) {
    return !userAccessRights.excludedUsers!.some(
      (allowedType: AccessUserTypes) =>
        allowedType.endsWith("_ALL")
          ? userType.startsWith(allowedType.slice(0, -4))
          : userType === allowedType,
    );
  }

  return userAccessRights.allowedUsers.some((allowedType) =>
    allowedType.endsWith("_ALL")
      ? userType.startsWith(allowedType.slice(0, -4))
      : userType === allowedType,
  );
};

const useNavigateToIndex = (
  userAccessRights: UserAccessProps,
  accountData: GetAccountResponse | undefined,
) => {
  const navigate = useNavigate();

  const {
    paymentMethods,
    membershipType,
    billToContact,
    shipToContact,
    currentSubscription,
    isValidCurrency,
  } = accountData?.account ?? {};
  const hasAddress = !!(shipToContact && billToContact);

  useEffect(() => {
    if (
      accountData &&
      membershipType &&
      !isAllowedUser(
        membershipType,
        userAccessRights,
        currentSubscription,
        paymentMethods,
        hasAddress,
        isValidCurrency ?? true,
      )
    ) {
      navigate(routes.index);
    }
  }, [
    membershipType,
    userAccessRights,
    navigate,
    currentSubscription,
    paymentMethods,
    accountData,
    hasAddress,
    isValidCurrency,
  ]);
};

const useUnfinishedOrUnverifiedUserCheck = () => {
  const { data: accountData } = useGetAccount();
  const membershipType = accountData?.account.membershipType;

  useEffect(() => {
    if (MembershipType.UNFINISHED === membershipType) {
      // The customer is not a legacy hardware user but they don't have subscription either (not even expired one).
      // They should be asked to finish the signup flow.
      throw throwError("errorOnB2CNoSubNoAccessOrNullMembershipType");
    } else if (MembershipType.UNVERIFIED === membershipType) {
      // The customer's account is unverified. They should be asked to verify it by requesting a new password
      // through forgot password feature.
      throw throwError("errorOnUnverifiedAccount");
    }
  }, [membershipType]);
};

export const UserAccessCheck = ({
  userAccessRights,
  children,
}: UserAccessCheckProps) => {
  const { data: accountData, isFetching, isError, error } = useGetAccount();
  const moiFlag = useFlag(Flags.MOI_AUTH);

  const {
    currentSubscription,
    membershipType,
    paymentMethods,
    billToContact,
    shipToContact,
    isValidCurrency,
  } = accountData?.account ?? {};
  const hasAddress = !!(shipToContact && billToContact);

  useUnfinishedOrUnverifiedUserCheck();
  useNavigateToIndex(userAccessRights, accountData);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    if (moiFlag) logger.error("Error getting account", error);
    else throw throwError("errorOnGetAccount", error);
    return null;
  }

  if (!membershipType) {
    // Account not found. The customer should create the account and membership through the signup flow.
    if (moiFlag) logger.error("Membership type not found in account data.");
    else throw throwError("errorOnB2CNoSubNoAccessOrNullMembershipType");
    return null;
  }

  if (
    !isAllowedUser(
      membershipType,
      userAccessRights,
      currentSubscription,
      paymentMethods,
      hasAddress,
      isValidCurrency ?? true,
    )
  ) {
    // Return null to prevent flash of content before redirect
    return null;
  }
  return children;
};
