import { useNavigate } from "react-router-dom";
import { t } from "translations";

import { Dialog } from "ui/components/Dialog";
import { SuccessIcon } from "ui/components/Icons";

import { routes } from "../routes";

interface PasswordSuccessDialogProps {
  dialogRef: React.RefObject<HTMLDialogElement>;
  onClose?: () => void;
}
export const PasswordSuccessDialog = ({
  dialogRef,
  onClose,
}: PasswordSuccessDialogProps) => {
  const navigate = useNavigate();
  return (
    <Dialog
      ref={dialogRef}
      onCloseClick={onClose}
      icon={<SuccessIcon />}
      title={t("membership_hub_password_changed")}
      textContents={[t("membership_hub_password_reset_successful")]}
      primaryButton={{
        text: t("membership_hub_return_to_hub_home"),
        onClick: () => navigate(routes.index),
      }}
    />
  );
};
