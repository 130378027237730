import { BillingPeriod } from "../types";

export const periodToProductPriceKey = (
  period: BillingPeriod,
): `membership_hub_${"monthly" | "yearly"}_price` => {
  switch (period) {
    case BillingPeriod.Months:
      return "membership_hub_monthly_price";
    case BillingPeriod.Years:
      return "membership_hub_yearly_price";
    default:
      throw new Error("Price locale key not implemented");
  }
};

export const periodToProductPlanKey = (
  period: BillingPeriod,
): `membership_hub_${"monthly" | "yearly"}_plan` => {
  switch (period) {
    case BillingPeriod.Months:
      return "membership_hub_monthly_plan";
    case BillingPeriod.Years:
      return "membership_hub_yearly_plan";
    default:
      throw new Error("Price locale key not implemented");
  }
};

export const periodToSignupPaymentMethodSubtitleWithPriceKey = (
  period: BillingPeriod,
): `membership_signup_payment_method_subtitle_${
  | "monthly"
  | "yearly"}_price` => {
  switch (period) {
    case BillingPeriod.Months:
      return "membership_signup_payment_method_subtitle_monthly_price";
    case BillingPeriod.Years:
      return "membership_signup_payment_method_subtitle_yearly_price";
    default:
      throw new Error("Price locale key not implemented");
  }
};

export const periodToHubPaymentMethodSubtitleWithPriceKey = (
  period: BillingPeriod,
): `membership_hub_payment_method_subtitle_${"monthly" | "yearly"}_price` => {
  switch (period) {
    case BillingPeriod.Months:
      return "membership_hub_payment_method_subtitle_monthly_price";
    case BillingPeriod.Years:
      return "membership_hub_payment_method_subtitle_yearly_price";
    default:
      throw new Error("Price locale key not implemented");
  }
};

export const periodToProductNameKey = (
  period: BillingPeriod,
): `membership_hub_${"monthly" | "yearly"}_product` => {
  switch (period) {
    case BillingPeriod.Months:
      return "membership_hub_monthly_product";
    case BillingPeriod.Years:
      return "membership_hub_yearly_product";
    default:
      throw new Error("Product name locale key not implemented");
  }
};

export const periodToProductPlanNameKey = (
  period: BillingPeriod,
): `membership_hub_${"monthly" | "yearly"}_product_plan` => {
  switch (period) {
    case BillingPeriod.Months:
      return "membership_hub_monthly_product_plan";
    case BillingPeriod.Years:
      return "membership_hub_yearly_product_plan";
    default:
      throw new Error(
        "Billing Period from recurringFee is missing. Product plan locale key not implemented",
      );
  }
};
