import { useState } from "react";

import isEqual from "lodash/isEqual";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import {
  AddressForm,
  AddressFormProps,
} from "apps-common/components/AddressForm";
import {
  FormValues,
  emptyForm,
  emptyAddress,
} from "apps-common/components/AddressForm/types";
import { useAddressTaxValidation } from "apps-common/components/AddressForm/useAddressTaxValidation";
import { AddressSuggestionModal } from "apps-common/components/AddressSuggestionModal";
import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { useGetSellToCountries } from "apps-common/hooks/useSellToCountries";
import { Address, BillingPeriod, Problem } from "apps-common/types";
import {
  convertAddressStateNameTo2CharCode,
  convertAddressToContact,
  convertContactToAddress,
  postGridEnabledCountries,
} from "apps-common/utils/address";
import { track } from "apps-common/utils/analytics";
import { throwError } from "apps-common/utils/errorHandler";
import { useFlag, Flags } from "apps-common/utils/featureFlags";
import { getRatePlan } from "apps-common/utils/getProduct";
import { removeEmptyStringOrNilProperties } from "apps-common/utils/helpers";
import { logger } from "apps-common/utils/logger";
import { Header, Loader } from "ui";
import { MainContainer } from "ui/styles/containers";

import { routes } from "../routes";
import { useStore } from "../store";
import { isExpiredSubscription, isRenewAllowed } from "../utils/member";

const useGetCountryData = () => {
  const { data: accountData } = useGetAccount();
  const billToContact = accountData?.account.billToContact ?? null;
  const shipToContact = accountData?.account.shipToContact ?? null;
  const isExpiredSub = isExpiredSubscription(
    accountData?.account?.currentSubscription?.subscriptionState,
  );
  const billingCountry = isExpiredSub ? "" : (billToContact?.country ?? "");
  const shippingCountry = isExpiredSub ? "" : (shipToContact?.country ?? "");

  const isSameCountry = billingCountry === shippingCountry;

  return {
    values: {
      ...emptyForm,
      billingAddress: {
        ...emptyAddress,
        country: billingCountry,
      },

      shippingAddress: {
        ...emptyAddress,
        country: shippingCountry,
      },
      isUnifiedAddress: isExpiredSub ? true : isSameCountry,
    },
  };
};

export const AddressPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [showAddressSuggestionModal, setShowAddressSuggestionModal] =
    useState(false);
  const [problems, setProblems] = useState<Problem[]>([]);
  const setRatePlan = useStore((state) => state.setRatePlan);
  const setUserAddressForm = useStore((state) => state.setUserAddressForm);
  const setBillingCountryIsoAlpha3Code = useStore(
    (state) => state.setBillingCountryIsoAlpha3Code,
  );
  const addressvalidationSuggestionsFlag = useFlag(
    Flags.ADDRESS_VALIDATION_SUGGESTIONS,
  );
  const {
    data: accountData,
    error: errorInGetAccount,
    isError: isErrorInGetAccount,
    isFetching: isFetchingAccountData,
  } = useGetAccount();

  const {
    data: countryData,
    isFetching: isFetchingCountries,
    isError: isErrorGetCountries,
    error: errorInGetCountries,
  } = useGetSellToCountries(!!accountData?.account);

  const hasPaymentMethods = Boolean(accountData?.account.paymentMethods.length);
  const handleBackClick = () => {
    hasPaymentMethods
      ? navigate(routes.paymentDetailsPageSelectAddressUsage)
      : navigate(routes.index);
  };
  const header = (
    <Header
      appType="hub"
      pageType="create"
      title={intl.formatMessage({
        id: "membership_signup_billing_address_title",
      })}
      subTitle={intl.formatMessage({
        id: "membership_hub_address_page_sub_text",
      })}
      onBackClick={handleBackClick}
    />
  );

  const { values } = useGetCountryData();
  const formMethods = useForm<FormValues>({
    defaultValues: emptyForm,
    values,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
    criteriaMode: "all",
    mode: "onTouched",
  });
  const { mutateAsync: addressValidationMutate } =
    useAddressTaxValidation(formMethods);

  if (isFetchingCountries || isFetchingAccountData) {
    return (
      <>
        {header}
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  if (isErrorInGetAccount || !accountData) {
    throw throwError("errorOnGetAccount", errorInGetAccount);
  }

  if (isErrorGetCountries || !countryData) {
    throw throwError("errorOnGettingCountries", errorInGetCountries);
  }

  const { shipToContact, currentSubscription, email } = accountData.account;

  if (!currentSubscription) {
    // Should never happen, as at this point user should have a subscription
    throw throwError("hubGeneralError", "No subscription found for user.");
  }

  const isRenewFlow = isRenewAllowed(currentSubscription.renewalState);

  const isAddressManuallyVerified = !!shipToContact?.manuallyVerified; //only place manuallyVerified is used and rest place we dont need in ship or billing address

  const nextPage =
    isRenewFlow && !currentSubscription.pendingCancellation
      ? routes.selectPlan
      : routes.updatePaymentMethod;

  // only allowed EXPIRED subscription users or users without recurringFee (placeholders)
  const isCountryChangeAllowed =
    isExpiredSubscription(currentSubscription.subscriptionState) ||
    !currentSubscription.recurringFee; // Typewise recurringfee is safer to check
  const selectProduct = (country: string) => {
    const ratePlan =
      !isExpiredSubscription(currentSubscription.subscriptionState) &&
      currentSubscription.recurringFee
        ? currentSubscription.recurringFee
        : getRatePlan(
            accountData.membershipOffering.products,
            country,
            BillingPeriod.Months,
          );
    ratePlan && setRatePlan(ratePlan);
  };

  const skipValidation = (shippingAddress: Address): boolean => {
    // Skip address validation if manually verified
    const userInput = removeEmptyStringOrNilProperties(shippingAddress);
    const verifiedAddressFromServer =
      shipToContact &&
      convertAddressStateNameTo2CharCode(
        convertContactToAddress(shipToContact),
        countryData,
      );

    return (
      isAddressManuallyVerified && isEqual(userInput, verifiedAddressFromServer)
    );
  };

  const onSubmit: AddressFormProps["onSubmit"] = async (data) => {
    setBillingCountryIsoAlpha3Code(data.billingAddress.isoAlpha3Code ?? "");
    setUserAddressForm({
      shippingAddress: data.shippingAddress,
      billingAddress: data.billingAddress,
    });

    // Set product and navigate to next page if tax validation is success
    const onValidationSuccess = () => {
      selectProduct(data.shippingAddress.country);
      track({
        event: "Address Added",
      });
      logger.info("Address added, continuing to next page", { nextPage });
      navigate(nextPage);
    };

    if (skipValidation(data.shippingAddress)) {
      onValidationSuccess();
    } else {
      const shipToContact = convertAddressToContact(
        data.shippingAddress,
        email,
      );

      await addressValidationMutate(shipToContact, {
        onSuccess: ({ success, problems }) => {
          if (success) {
            onValidationSuccess();
          } else {
            if (
              postGridEnabledCountries.includes(shipToContact.country) &&
              problems.some((problem) => problem.correctedValue) &&
              addressvalidationSuggestionsFlag
            )
              setShowAddressSuggestionModal(true);
            setProblems(problems);
            const filteredProblems = problems.map(
              ({ correctedValue, ...rest }) => rest,
            );
            logger.warn("Address Validation Failed", {
              problems: filteredProblems,
            });
            // Track address validation failures
            track({
              event: "Address Validation Failed",
              payload: {
                reason: "INVALID_ADDRESS",
                problems: filteredProblems,
              },
            });
          }
        },
      });
    }
  };

  return (
    <>
      {showAddressSuggestionModal &&
        formMethods.getValues("billingAddress") && (
          <AddressSuggestionModal
            showModal={showAddressSuggestionModal}
            setShowModal={setShowAddressSuggestionModal}
            currentAddress={formMethods.getValues("billingAddress")}
            setAddressFormData={formMethods.setValue}
            problems={problems}
            clearErrors={formMethods.clearErrors}
          />
        )}
      {header}
      <MainContainer>
        <FormProvider {...formMethods}>
          <AddressForm
            onSubmit={onSubmit}
            onSubmitClick={() => {
              isRenewFlow
                ? track({
                    event: "CTA Clicked",
                    payload: {
                      cta: "next",
                      action: "renew_next_step",
                      step: "billing_address",
                    },
                  })
                : track({
                    event: "CTA Clicked",
                    payload: {
                      cta: "next",
                      action: "update_pm_next_step",
                      step: "billing_address",
                    },
                  });
            }}
            countries={countryData}
            allowCountryChange={isCountryChangeAllowed}
          />
        </FormProvider>
      </MainContainer>
    </>
  );
};
