import { ReactNode } from "react";

import { styled } from "styled-components";

import { HeaderText, Text } from "../styles/text";

import ErrorIcon from "./assets/error-icon.svg?react";

interface ErrorPageComponent {
  header: ReactNode;
  message: ReactNode;
}

const ErrorBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 296px;
  gap: 12px;
  margin-top: 5rem;
`;

export const ErrorPageComponent = ({ header, message }: ErrorPageComponent) => (
  <ErrorBody>
    <ErrorIcon />
    <HeaderText $textAlign="center" $fontSize="xxxlarge">
      {header}
    </HeaderText>
    <Text
      $textAlign="center"
      $fontSize="medium"
      $lineHeight="150%"
      $color="grayLightest"
    >
      {message}
    </Text>
  </ErrorBody>
);
