import { InputHTMLAttributes, PropsWithChildren, forwardRef } from "react";

import { styled } from "styled-components";

import { TextProps } from "../../styles/text";

import { Container } from "./common/Container";
import { ErrorLabel } from "./common/Label";

const CheckboxInput = styled.input.attrs({ type: "checkbox" })(
  ({ theme }) => `
  cursor: pointer;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid ${theme.colors.white2};
  padding: 3px;
  margin: 0;

  &:checked::after {
    content: "";
    display: block;
    height: 100%;
    background-color: ${theme.colors.helsinkiBlue};
  }
`,
);

const StyledLabel = styled.label<TextProps>(
  ({ theme, $textAlign, $fontSize = "medium", $color = "grayLightest" }) => `
  text-align: ${$textAlign};
  font-size: ${theme.fontSizes[$fontSize]};
  font-weight: 400;
  font-style: normal;
  color: ${theme.colors[$color]};
  vertical-align: super;
  line-height: 150%;
  &:hover {
    cursor: pointer;
  }

  a {
    padding: 0;
  }
`,
);

const StyledErrorLabel = styled(ErrorLabel)`
  margin-left: 36px;
  padding: 0;
`;

type CheckboxProps = {
  htmlFor?: string;
  error?: string;
} & InputHTMLAttributes<HTMLInputElement> &
  Omit<TextProps, "$textAlign">;

const CustomCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Checkbox = forwardRef<
  HTMLInputElement,
  PropsWithChildren<CheckboxProps>
>(
  (
    {
      $fontSize = "medium",
      $color = "grayLightest",
      error,
      htmlFor,
      children,
      ...inputProps
    },
    ref,
  ) => (
    <Container>
      <CustomCheckboxContainer>
        <CheckboxInput ref={ref} id={htmlFor} {...inputProps} />
        <StyledLabel
          $textAlign="left"
          $fontSize={$fontSize}
          $color={$color}
          htmlFor={htmlFor}
        >
          {children}
        </StyledLabel>
      </CustomCheckboxContainer>
      {error && <StyledErrorLabel role="alert">{error}</StyledErrorLabel>}
    </Container>
  ),
);
