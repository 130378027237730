import { ReactNode, forwardRef } from "react";

import { styled } from "styled-components";
import { t } from "translations";

import { Address, Problem } from "apps-common/types";
import { RadioGroupOption, RadioGroupOptionProps } from "ui";
import { SmallText, SectionTitle } from "ui/styles/text";

const FieldSet = styled.fieldset(
  ({ theme }) => `
    border: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 12px;

    & > div {
      border: none;
      background-color: ${theme.colors.grayDark};
      border-radius: 12px;
    }

    & > div:last-child {
      border-radius: 12px;
    }
`,
);

const LabelText = styled(SmallText)(
  ({ theme }) => `
  gap: 8px;
  color: ${theme.colors.white2};
  & > span {
    color: ${theme.colors.lightRed};
  }
`,
);

const highlightErrors = (problems: Problem[] = [], address?: Address) => {
  if (!address) {
    return null;
  }
  const { firstName, lastName, country, ...restOfAddress } = address;
  const highlightedAddress: Record<string, ReactNode> = {};

  Object.keys(restOfAddress).forEach((key) => {
    const value = address[key as keyof Address];
    if (problems.find((problem) => problem.field === key)) {
      highlightedAddress[key] = <span key={key}>{value}</span>;
    } else highlightedAddress[key] = value;
  });
  return highlightedAddress;
};

interface AddressSuggestionSelectorProps
  extends Omit<RadioGroupOptionProps, "labelText"> {
  currentAddress: Address;
  correctedAddress: Address;
  problems: Problem[];
}

export const AddressSuggestionSelector = forwardRef<
  HTMLInputElement,
  AddressSuggestionSelectorProps
>(({ currentAddress, correctedAddress, problems, ...radioProps }, ref) => {
  const errorAddress = highlightErrors(problems, currentAddress);
  return (
    <FieldSet role="radiogroup">
      <RadioGroupOption
        {...radioProps}
        ref={ref}
        key={"suggested"}
        value={"suggested"}
        data-testid={"suggested-address"}
        checked={true}
        labelText={
          <div>
            <SectionTitle $margin="0 0 8px">
              {t(
                "membership_hub_address_validation_suggestion_suggested_address_header",
              )}
            </SectionTitle>
            <LabelText>
              {correctedAddress.address1}
              {", "}
              {correctedAddress.city +
                ", " +
                correctedAddress.state +
                ", " +
                correctedAddress.postalCode}
            </LabelText>
          </div>
        }
      />
      <RadioGroupOption
        {...radioProps}
        ref={ref}
        key={"entered"}
        value={"entered"}
        data-testid={"entered-address"}
        labelText={
          <div>
            <SectionTitle $margin="0 0 8px">
              {t(
                "membership_hub_address_validation_suggestion_entered_address_header",
              )}
            </SectionTitle>
            <LabelText>
              {" "}
              {errorAddress?.address1}
              {", "}
              {errorAddress?.city}
              {", "}
              {errorAddress?.state}
              {", "}
              {errorAddress?.postalCode}
            </LabelText>
          </div>
        }
      />
    </FieldSet>
  );
});
