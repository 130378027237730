import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

import { Paragraph, SectionTitle } from "../styles/text";
import { media } from "../themes/signup";

import { CircleArrowIcon } from "./Icons";

interface CardProps {
  title: string;
  description: string;
  backgroundImage: string;
  href?: string;
  ariaLabel: string;
  onClick?: () => void;
  size?: "short" | "tall";
}

interface CardContainerProps {
  $bgImage: string;
  $height: CardSize;
}

enum CardSize {
  short = "160px",
  tall = "332px",
}

const CardContainer = styled.div<CardContainerProps>(
  ({ $bgImage, $height }) => `
  display: flex;
  justify-content: space-between;
  padding: ${$height === CardSize.tall ? "190px 24px 0px" : "40px 24px"};
  width: 100%;
  min-width: 300px;
  min-height: ${$height};
  border-radius: 8px;
  margin: 8px 0;
  transition: box-shadow 0.3s ease-in-out;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent),
    url(/${$bgImage});
  background-size: cover;
  background-position: center;
  `,
);

const CardContent = styled.div`
  width: 76%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.large`
    width: 100%;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

export const Card = ({
  title,
  description,
  backgroundImage,
  href,
  ariaLabel,
  onClick,
  size = "short",
}: CardProps) => {
  const intl = useIntl();
  const height = CardSize[size];

  const content = (
    <CardContent>
      <SectionTitle $fontSize="xlarge" $lineHeight="110%">
        {title}
      </SectionTitle>
      <Paragraph $lineHeight="140%" $fontSize="small">
        {description}
      </Paragraph>
    </CardContent>
  );

  if (onClick && href) {
    return (
      <StyledLink
        to={href}
        onClick={onClick}
        aria-label={
          href
            ? intl.formatMessage(
                { id: "membership_hub_accessibility_navigate_to" },
                { link: ariaLabel },
              )
            : ariaLabel
        }
      >
        <CardContainer $bgImage={backgroundImage} $height={height}>
          {content}
          <IconContainer>
            <CircleArrowIcon />
          </IconContainer>
        </CardContainer>
      </StyledLink>
    );
  }

  return (
    <CardContainer $bgImage={backgroundImage} $height={height}>
      {content}
    </CardContainer>
  );
};
