import { UseQueryResult } from "@tanstack/react-query";
import JSZip from "jszip";

import { InvoiceGetResponse } from "../hooks/useGetInvoice";
const createDownloadLink = (url: string, filename: string): void => {
  const link = document.createElement("A") as HTMLAnchorElement;
  link.href = url;
  link.download = filename;
  link.hidden = true;
  link.click();
  link.remove();
};
export const downloadInvoices = (
  queries: UseQueryResult<InvoiceGetResponse, Error>[],
  selectedInvoices: string[],
  downloadButtonClicked: boolean,
  setDownloadButtonClicked: (status: boolean) => void,
) => {
  const allQueriesSuccess = queries.every((query): boolean => query.isSuccess);
  if (
    allQueriesSuccess &&
    selectedInvoices.length > 1 &&
    downloadButtonClicked
  ) {
    // This is for when multiple invoices are selected and we get zip file
    setDownloadButtonClicked(false);
    const zip = new JSZip();
    queries.forEach((item) => {
      if (item.data)
        zip.file("invoice_" + item.data.invoiceId + ".pdf", item.data.file);
    });
    zip.generateAsync({ type: "blob" }).then((content: Blob) => {
      const url = URL.createObjectURL(
        new Blob([content], { type: "aplication/zip" }),
      );
      createDownloadLink(url, "oura-invoices.zip");
    });
  } else if (
    allQueriesSuccess &&
    selectedInvoices.length === 1 &&
    downloadButtonClicked &&
    queries[0].data
  ) {
    // This if for when only one invoice is selected and we want pdf
    setDownloadButtonClicked(false);
    const url = URL.createObjectURL(
      new Blob([queries[0].data.file], { type: "aplication/pdf" }),
    );

    createDownloadLink(
      url,
      "oura-invoice-" + queries[0].data.invoiceId + ".pdf",
    );
  }
};
