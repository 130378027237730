import { styled } from "styled-components";

import { Row } from "../../styles/layout";
import { Link } from "../../styles/text";

import facebook from "./assets/facebook.svg";
import instagram from "./assets/instagram.svg";
import pinterest from "./assets/pinterest.svg";
import tiktok from "./assets/tiktok.svg";
import twitter from "./assets/twitter.svg";
import youtube from "./assets/youtube.svg";

const icons = {
  facebook: {
    link: "https://www.facebook.com/ouraring?utm_source=web&utm_medium=organic&utm_campaign=alwayson_websocial&utm_content=static",
    logo: facebook,
  },
  instagram: {
    link: "https://www.instagram.com/ouraring/?utm_source=web&utm_medium=organic&utm_campaign=alwayson_websocial&utm_content=static",
    logo: instagram,
  },
  twitter: {
    link: "https://twitter.com/ouraring?utm_source=web&utm_medium=organic&utm_campaign=alwayson_websocial&utm_content=static",
    logo: twitter,
  },
  youtube: {
    link: "https://www.youtube.com/channel/UCf-xFf4xPcT9DVdOkcCaScg?utm_source=web&utm_medium=organic&utm_campaign=alwayson_websocial&utm_content=static",
    logo: youtube,
  },
  tiktok: {
    link: "https://www.tiktok.com/@ouraring?utm_source=web&utm_medium=organic&utm_campaign=alwayson_websocial&utm_content=static",
    logo: tiktok,
  },
  pinterest: {
    link: "https://www.pinterest.com/Ouraring/?utm_source=web&utm_medium=organic&utm_campaign=alwayson_websocial&utm_content=static",
    logo: pinterest,
  },
} as const;

const iconNames = Object.keys(icons) as (keyof typeof icons)[];

const Image = styled.img`
  height: 32px;
  width: 32px;
`;

interface SomeIconProps {
  icon: keyof typeof icons;
}

export const SomeIcon = ({ icon }: SomeIconProps) => {
  const { link, logo } = icons[icon];
  return (
    <Link href={link} target="_blank" $decoration="none" $fontSize="16px">
      <Image src={logo} alt={icon} />
    </Link>
  );
};

export const SomeIcons = () => (
  <Row $justifyContent="space-evenly">
    {iconNames.map((icon) => (
      <SomeIcon key={icon} icon={icon} />
    ))}
  </Row>
);
