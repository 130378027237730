import { ReactNode } from "react";

import { styled } from "styled-components";
import { t } from "translations";

import { Flags, useFlag } from "apps-common/utils/featureFlags";

import { Column, Row } from "../../styles/layout";
import { RingImage } from "../../styles/media";
import { HeaderText, Paragraph, StepIndicator } from "../../styles/text";
import { media } from "../../themes/signup";
import { BackButton } from "../BackButton";

import {
  StyledBaseHeader,
  AppType,
  PageType,
  StyledRingImageTitleHeaderContainer,
  Step,
} from "./BaseHeader";
import { OuraHeaderLogo } from "./OuraHeaderLogo";

interface MobileHeaderProps {
  appType: AppType;
  pageType: PageType;
  onBackClick?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  steps?: Step;
  error?: string;
  personalRingImage?: React.ReactNode;
  testId?: string;
  createdAt?: string;
  prepaid?: boolean;
}

const TiltedRingImage = styled(RingImage)`
  position: absolute;
  right: -20px;
  top: -20px;
  width: 180px;
  height: 180px;
  object-fit: cover;

  ${media.medium`
      right: -50px;
      top: -10px;
    `}
`;

export const MobileHeader = ({
  appType,
  pageType,
  onBackClick,
  title,
  subTitle,
  steps,
  testId,
  createdAt,
  prepaid,
}: MobileHeaderProps) => {
  const oreoFlag = useFlag(Flags.OREO);
  const welcomePageRingImagePath = oreoFlag
    ? "/blurred_silver.png"
    : "/oura-ring.webp";
  const completePageRingImagePath = oreoFlag
    ? "/blurred_gold.png"
    : "/oura-ring.webp";
  const headerTextSize = appType === "hub" ? "large" : "xxlarge";
  const subTitleTextSize = appType === "hub" ? "small" : "medium";
  const membershipHubText = (
    <Paragraph $fontSize="small" $padding="0 0 0 20px">
      {t("membership_hub_feature_name")}
    </Paragraph>
  );
  const stepsComponent = (
    <StepIndicator style={{ marginBottom: 0 }}>
      {t("membership_signup_step", {
        current_step: steps?.current,
        total_steps: steps?.total,
      })}
    </StepIndicator>
  );
  const headerText = (width: string) => (
    <>
      {prepaid && appType === "signup" && (
        <Paragraph $fontSize="large">Welcome</Paragraph>
      )}
      <HeaderText
        $fontSize={headerTextSize}
        style={{
          paddingTop: pageType === "complete" ? "20px" : "0",
          width: width,
          overflowWrap: "break-word",
        }}
        data-testid={testId}
      >
        {title}
      </HeaderText>
    </>
  );
  const subTitleText = (
    <Paragraph
      $textAlign="left"
      data-testid="pageSubtitle"
      $fontSize={subTitleTextSize}
      $padding="0"
      $margin="5px 0 0 0"
      $color={
        pageType === "create" || pageType === "hubHome"
          ? "grayLightest"
          : "white"
      }
      style={{
        paddingTop: pageType === "complete" ? "10px" : "0",
        width: pageType === "complete" ? "90%" : "100%",
      }}
    >
      {subTitle}
    </Paragraph>
  );

  // should display personalRingImage after MVP (signup complete page)
  const singUpCompletePageRingImage = (
    <RingImage
      src={completePageRingImagePath}
      alt="Ouraring"
      style={{ alignSelf: "center" }}
    />
  );

  const renderMobileHeader = () => {
    if (pageType === "landing") {
      return (
        <StyledBaseHeader $alignItems="center" $marginTop="0">
          <RingImage src={welcomePageRingImagePath} alt="Ouraring" />
          <StyledRingImageTitleHeaderContainer>
            <OuraHeaderLogo width="97px" height="30px" app={appType} />
            {appType === "hub" && (
              <Paragraph $fontSize="medium" $padding="20px 0 0 0">
                {t("membership_hub_feature_name")}
              </Paragraph>
            )}
            {title && (
              <HeaderText $margin="20px 0 0 0" $fontSize="xxlarge">
                {title}
              </HeaderText>
            )}
            {subTitle && (
              <Paragraph
                data-testid="pageSubtitle"
                $fontSize="medium"
                $padding="20px 0 0 0"
              >
                {subTitle}
              </Paragraph>
            )}
          </StyledRingImageTitleHeaderContainer>
        </StyledBaseHeader>
      );
    } else if (pageType === "create" || pageType === "complete") {
      return (
        <StyledBaseHeader>
          <Row
            $justifyContent="space-between"
            $alignItems="flex-end"
            $width="100%"
            $padding="0 0 20px 0"
          >
            <Row $alignItems="flex-end">
              <OuraHeaderLogo height="19px" width="60px" app={appType} />
              {appType === "hub" && membershipHubText}
            </Row>
            {onBackClick && <BackButton onClick={onBackClick} />}
          </Row>
          {steps && stepsComponent}
          {title && headerText("90%")}
          {subTitle && subTitleText}
          {pageType === "complete" && singUpCompletePageRingImage}
        </StyledBaseHeader>
      );
    } else if (pageType === "hubHome") {
      return (
        <StyledBaseHeader $marginTop="30px">
          <Row $width="100%">
            <Row
              $justifyContent="space-between"
              $alignItems="flex-end"
              $width="60%"
              $padding="0 0 20px 0"
            >
              <Row $alignItems="flex-end">
                <OuraHeaderLogo height="19px" width="60px" app={appType} />
                {membershipHubText}
              </Row>
            </Row>
            <Column $width="100%">
              {headerText("50%")}
              {createdAt ? (
                <Paragraph $margin="0" $fontSize="small">
                  {t("membership_hub_member_since", {
                    date: new Date(createdAt).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }),
                  })}
                </Paragraph>
              ) : null}
            </Column>
            <TiltedRingImage src="/stealth-ring.webp" alt="Ouraring" />
          </Row>
        </StyledBaseHeader>
      );
    } else if (pageType === "error") {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            {appType === "hub" && (
              <Paragraph $fontSize="small" $padding="0 0 0 20px">
                {t("membership_hub_feature_name")}
              </Paragraph>
            )}
          </Row>
        </StyledBaseHeader>
      );
    }
    return (
      <StyledBaseHeader>
        <OuraHeaderLogo height="29px" width="93px" app={appType} />
      </StyledBaseHeader>
    );
  };

  return renderMobileHeader();
};
