import { gql } from "graphql-request";

import { BillingPeriod } from "../types";
import { axiosQuery } from "../utils/axiosQuery";

const MUTATION = gql`
  mutation ChangeBillingPeriod($billingPeriod: ApiBillingPeriod!) {
    changeBillingPeriod(billingPeriod: $billingPeriod) {
      billingPeriod
      nextBillingDate
    }
  }
`;

export interface ChangeBillingPeriodRequest {
  billingPeriod: BillingPeriod;
}

export interface ChangeBillingPeriodResponse {
  billingPeriod: BillingPeriod;
  nextBillingDate?: string; // TODO: nextBillingDate is optional on backend domain. But can it be undefined in this scenario?
}

export const changeBillingPeriod = (vars: ChangeBillingPeriodRequest) =>
  axiosQuery<{
    changeBillingPeriod: ChangeBillingPeriodResponse;
  }>("ChangeBillingPeriod", MUTATION, vars);
