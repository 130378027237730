import { useEffect } from "react";

import { Loader } from "ui";

import { useQueryParams } from "../../hooks/useQueryParams";

import { CallbackError } from "./CallbackError";

import type { OnErrorCallback, OnSuccessCallback } from "./index";

const useHandleCallback = ({ onSuccess, onError }: HSACallbackPageProps) => {
  const queryParams = useQueryParams();
  const paymentMethodId = queryParams.get("paymentMethodId");
  const flow = queryParams.get("flow") ?? undefined;

  useEffect(() => {
    if (paymentMethodId) {
      onSuccess(paymentMethodId, flow);
    }
  }, [paymentMethodId, flow, onSuccess, onError]);
};

interface HSACallbackPageProps {
  onSuccess: OnSuccessCallback;
  onError: OnErrorCallback;
}

export const HSACallbackPage = (props: HSACallbackPageProps) => {
  useHandleCallback(props);
  const queryParams = useQueryParams();
  const paymentMethodId = queryParams.get("paymentMethodId");

  if (!paymentMethodId) {
    props.onError(
      new CallbackError(
        "HSACallbackPage called without paymentMethodId query param",
        "MISSING_PAYMENT_METHOD_ID",
      ),
    );
    return null;
  }

  return <Loader />;
};
