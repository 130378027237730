import { gql } from "graphql-request";

import { axiosQuery } from "../utils/axiosQuery";

export interface StartPaypalExpressCheckoutInput {
  returnUrl: string;
  cancelUrl: string;
  isUnitedStates: boolean;
  currency: string;
}

interface StartPaypalExpressCheckoutOutput {
  startPaypalExpressCheckout: {
    token: string;
    url: string;
  };
}

const START_PAYPAL_MUTATION = gql`
  mutation StartPaypalExpressCheckout(
    $returnUrl: String!
    $cancelUrl: String!
    $isUnitedStates: Boolean!
    $currency: String!
  ) {
    startPaypalExpressCheckout(
      returnUrl: $returnUrl
      cancelUrl: $cancelUrl
      isUnitedStates: $isUnitedStates
      currency: $currency
    ) {
      token
      url
    }
  }
`;

export const startPaypalExpressCheckout = async (
  req: StartPaypalExpressCheckoutInput,
) =>
  axiosQuery<StartPaypalExpressCheckoutOutput>(
    "StartPaypalExpressCheckout",
    START_PAYPAL_MUTATION,
    req,
  );
