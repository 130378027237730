import { ReactNode } from "react";

import { useIsDesktopView } from "../useIsDesktopView";

import { AppType, PageType, Step } from "./BaseHeader";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

interface HeaderProps {
  isDesktopView?: boolean;
  appType: AppType;
  pageType: PageType;
  steps?: Step;
  onBackClick?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  error?: string;
  children?: React.ReactNode;
  testId?: string;
  createdAt?: string;
  prepaid?: boolean;
}

// logos, heading texts, navigations(back) images go here
export const Header = (props: HeaderProps) => {
  const [isDesktopView] = useIsDesktopView();

  return isDesktopView ? (
    <DesktopHeader isDesktopView={isDesktopView} {...props} />
  ) : (
    <MobileHeader isDesktopView={isDesktopView} {...props} />
  );
};
