import { styled } from "styled-components";

interface ButtonProps {
  $margin?: string;
}

export const Button = styled.button<ButtonProps>(
  ({ theme, $margin = "auto 0 0 0" }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 26px;
  width: 100%;
  max-width: 345px;
  line-height: 125%;
  order: 0;
  border: 0;
  color: ${theme.colors.grayDarkest};
  text-align: center;
  font-weight: 700;
  font-size: ${theme.fontSizes.medium};
  font-style: normal;
  cursor: pointer;
  background-color: ${theme.colors.white2};
  margin: ${$margin};

  &[disabled] {
    cursor: not-allowed;
    background-color: ${theme.colors.grayLight};
  }
  `,
);
