import { OuraConsentManager } from "@jouzen/consent-manager";
import { useIntl } from "react-intl";

const SEGMENT_KEY: string = import.meta.env.VITE_ANALYTICS_KEY;

export const ConsentManager = () => {
  const intl = useIntl();
  const locale = intl.locale;
  if (!SEGMENT_KEY) return null;

  return <OuraConsentManager segmentKey={SEGMENT_KEY} intlProps={{ locale }} />;
};
