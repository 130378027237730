import { useNavigate } from "react-router-dom";
import { t } from "translations";

import { logger } from "apps-common/utils/logger";
import { ErrorBanner, Loader, Header } from "ui";
import { MainContainer } from "ui/styles/containers";

import { BillingHistory } from "../components/BillingInformation/BillingHistory";
import { useGetBillingHistory } from "../hooks/useGetBillingHistory";
import { routes } from "../routes";

export const ViewBillingHistoryPage = () => {
  const { data, error, isFetching } = useGetBillingHistory();
  const navigate = useNavigate();
  error && logger.error("Fetching billing history failed", { error });

  if (isFetching || !data) {
    return <Loader />;
  }

  const {
    invoices,
    account: { currency },
  } = data;

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t("membership_hub_billing_history_header")}
        onBackClick={() => navigate(routes.index)}
      />
      <MainContainer $padding="10px 0" $maxWidth="794px">
        {error && <ErrorBanner>{error.message}</ErrorBanner>}
        <BillingHistory currency={currency} invoices={invoices} />
      </MainContainer>
    </>
  );
};
