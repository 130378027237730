import { AnchorHTMLAttributes } from "react";

import { Link as ClientLink } from "react-router-dom";
import { styled } from "styled-components";

import { Link as ExternalLink } from "ui/styles/text";

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

// Copied styles from ui
const StyledLink = styled(ClientLink)(
  ({ theme }) => `
  font-family: ${theme.fonts};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: ${theme.colors.grayLightest};
  line-height: 125%;
  text-align: center;
`,
);

export const Link = ({ children, href, ...props }: LinkProps) => {
  if (href?.startsWith("http")) {
    <ExternalLink $fontSize="14px" href={href} {...props}>
      {children}
    </ExternalLink>;
  }

  return (
    <StyledLink to={href ?? ""} {...props}>
      {children}
    </StyledLink>
  );
};
