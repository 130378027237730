import { useState, useEffect, ReactNode } from "react";

import isEmpty from "lodash/isEmpty";
import { styled } from "styled-components";
import { t } from "translations";

import { Currency, Invoice } from "apps-common/types";
import { logger } from "apps-common/utils/logger";
import { Button, Checkbox } from "ui";
import { DownloadIcon } from "ui/components/Icons";
import { Background, Row } from "ui/styles/layout";
import { Paragraph, Text } from "ui/styles/text";
import { media } from "ui/themes/signup";

import { useGetInvoice } from "../../hooks/useGetInvoice";
import { downloadInvoices } from "../../utils/billingHistory";
import { Pagination } from "../Pagination";

interface BillingHistoryProps {
  currency: Currency;
  invoices: Invoice[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${Paragraph} {
    width: 60%;
    text-align: center;
    margin: 100px 0;
  }
`;

const PaddedRow = styled(Row)`
  margin-top: 24px;
  padding: 16px;
  width: 100%;
`;

const Ul = styled.ul(
  ({ theme }) => `
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:not(:first-child) {
    border-top: 1px solid ${theme.colors.grayMedium}
  }
`,
);

const Li = styled.li`
  padding: 24px 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;
const DownloadButton = styled(Button)`
  margin: 40px 0;
  gap: 10px;

  ${media.xlarge`
    max-width: 60%;
    align-self: flex-end;
    ${Button} {
      margin 24px 0;
    }
  `}
`;
const getStartIndex = (pageSize: number, currentPage: number): number => {
  if (currentPage === 1) return 0;
  else return (currentPage - 1) * pageSize;
};
const getEndIndex = (pageSize: number, currentPage: number): number => {
  if (currentPage === 1) return pageSize;
  else return (currentPage - 1) * pageSize + pageSize;
};
export const BillingHistory = ({ currency, invoices }: BillingHistoryProps) => {
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);
  const allChecked = selectedInvoices.length === invoices.length;
  const queries = useGetInvoice(selectedInvoices);
  const PAGESIZE = 10;
  const getInvoicePdf = () => {
    setDownloadButtonClicked(true);
    //Refetch only items that are not in react-querys cache/success
    queries.forEach((item) => {
      if (!item.isSuccess) item.refetch();
    });
  };

  const generateInvoiceComponent = ({
    id,
    date,
    amount,
    compensationType,
  }: Invoice): ReactNode => {
    const isCompensated = !!compensationType;
    return (
      <Li key={id}>
        <Checkbox
          checked={selectedInvoices.includes(id)}
          onChange={(e) => {
            const { checked } = e.target;
            if (checked) {
              setSelectedInvoices([...selectedInvoices, id]);
            } else {
              setSelectedInvoices([
                ...selectedInvoices.filter((oldId) => oldId !== id),
              ]);
            }
          }}
        >
          {new Date(date).toLocaleDateString(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        </Checkbox>
        <Text>
          {isCompensated
            ? t("membership_hub_invoice_compensated")
            : amount.toLocaleString(undefined, {
                style: "currency",
                currency,
              })}
        </Text>
      </Li>
    );
  };

  const invoiceComponents = invoices
    .slice(
      getStartIndex(PAGESIZE, selectedPage),
      getEndIndex(PAGESIZE, selectedPage),
    )
    .map(generateInvoiceComponent);

  const useHandleDownload = () =>
    useEffect(() => {
      downloadInvoices(
        queries,
        selectedInvoices,
        downloadButtonClicked,
        setDownloadButtonClicked,
      );
    });
  useHandleDownload();

  return (
    <Container>
      {!isEmpty(invoices) && (
        <>
          <PaddedRow>
            <Checkbox
              $color="white2"
              checked={allChecked}
              onChange={(e) => {
                const { checked } = e.target;
                if (checked) {
                  setSelectedInvoices(invoices.map((i) => i.id));
                } else {
                  setSelectedInvoices([]);
                }
              }}
            >
              {t("membership_hub_select_all", {
                number_of_invoices: invoices.length,
              })}
            </Checkbox>
          </PaddedRow>
          <Background>
            <Ul>{invoiceComponents}</Ul>
          </Background>
          {invoices.length / PAGESIZE > 1 && (
            <Pagination
              pageCount={Math.ceil(invoices.length / PAGESIZE)}
              setCurrentPage={(page) => setSelectedPage(page)}
              currentPage={selectedPage}
            />
          )}
          <DownloadButton
            $margin="24px 0"
            disabled={selectedInvoices.length === 0 || downloadButtonClicked}
            onClick={() => {
              logger.debug("Selected invoices", { selectedInvoices });
              getInvoicePdf();
            }}
          >
            {t("membership_hub_download_invoices")} <DownloadIcon />
          </DownloadButton>
        </>
      )}
      {invoices.length === 0 && (
        <Paragraph>{t("membership_hub_no_billing_history")}</Paragraph>
      )}
    </Container>
  );
};
