import { useIntl } from "react-intl";
import { useRouteError } from "react-router-dom";
import { t } from "translations/src/LocaleContext";

import { Header } from "ui";
import { ErrorPageComponent } from "ui/components/ErrorPageComponent";
import { MainContainer } from "ui/styles/containers";
import { Link } from "ui/styles/text";

import type { MessageKey } from "translations";

interface ErrorType {
  statusText?: string;
  message?: string;
}

type ErrorPageType = "notFound" | "error";

interface ErrorPageProps {
  errorPageType?: ErrorPageType;
  appType: "hub" | "signup";
}

const getNewPasswordLink = () =>
  t("membership_hub_unverified_account", {
    membership_hub_unverified_account_link_label: (
      <Link
        href="/request-password-reset"
        $fontSize="16px"
        $decoration="underline"
      >
        {t("membership_hub_unverified_account_link_label")}
      </Link>
    ),
  });

const getLoginLink = () =>
  t("membership_hub_moi_error_signup_required", {
    LinkToLogin: (linkText) => (
      <Link href="/login" $decoration="underline" $fontSize="16px">
        {linkText}
      </Link>
    ),
  });
export const ErrorPage = ({
  errorPageType = "error",
  appType,
}: ErrorPageProps) => {
  const defaultMessageByApp: MessageKey =
    appType === "hub"
      ? "membership_hub_general_error"
      : "membership_error_unknown";

  const headerKey: MessageKey =
    errorPageType === "notFound"
      ? "membership_error_page_not_found"
      : "membership_error_error_occured";
  const defaultMessageKey: MessageKey =
    errorPageType === "notFound"
      ? "membership_error_page_you_are_looking_for_not_exists"
      : defaultMessageByApp;
  const error = useRouteError() as ErrorType | undefined;
  const errorMessageKey =
    error?.statusText ?? error?.message ?? defaultMessageKey;

  const intl = useIntl();
  const header = intl.formatMessage({ id: headerKey });
  let message;
  try {
    if (errorMessageKey === "membership_hub_unverified_account") {
      message = getNewPasswordLink();
    } else if (errorMessageKey === "membership_hub_moi_error_signup_required") {
      message = getLoginLink();
    } else {
      message = intl.formatMessage({ id: errorMessageKey });
    }
  } catch (err) {
    message = intl.formatMessage({ id: defaultMessageKey });
  }
  return (
    <>
      <Header pageType="error" appType={appType} />
      <MainContainer>
        <ErrorPageComponent header={header} message={message} />
      </MainContainer>
    </>
  );
};
