import { InputHTMLAttributes, PropsWithChildren, forwardRef } from "react";

import { styled } from "styled-components";

const SwitchContainer = styled.label`
  display: inline-flex;
  gap: 8px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  flex-shrink: 0;
  position: relative;
  font-size: 20px;
  width: 2em;
`;

const SwitchInput = styled.input.attrs({ type: "checkbox" })(
  ({ theme }) => `
  position: absolute;
  appearance: none;
  opacity: 0;
  margin: 0;

  &:focus + span {
    outline: max(2px, 0.1em) solid ${theme.colors.grayMedium};
    outline-offset: max(2px, 0.05em);
  }

  &:checked + span {
    /* 38% opacity as hex is 61 */
    background-color: ${theme.colors.blueLight}61;
  }

  &:checked + span::after {
    background-color: ${theme.colors.blueLight};
    transform: translateX(20px);
  }
  `,
);

const Slider = styled.span(
  ({ theme }) => `
    height: 0.7em;
    width: 100%;
    background-color: ${theme.colors.grayLightest};
    border-radius: 0.5rem;
    /* Positioning the ball */
    position: relative;
    display: inline-flex;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      width: 1em;
      height: 1em;
      background-color: ${theme.colors.white};
      border-radius: 50%;
      transition: transform 0.2s ease-in;
      transform: translateX(0);
    }
`,
);

type SwitchProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type">;

export const Switch = forwardRef<
  HTMLInputElement,
  PropsWithChildren<SwitchProps>
>(({ children, ...props }, ref) => {
  return (
    <div>
      <SwitchContainer htmlFor={props.id}>
        <div>{children}</div>
        <InputContainer>
          <SwitchInput ref={ref} {...props} />
          <Slider />
        </InputContainer>
      </SwitchContainer>
    </div>
  );
});
