import { useEffect } from "react";

import { styled } from "styled-components";

import { HostedPageParams, PrepopulateFields } from "../../types";
import { renderForm } from "../../utils/zuora";

const ZuoraContainer = styled.div`
  /* stylelint-disable-next-line selector-id-pattern */
  #z_hppm_iframe {
    background: transparent;
    width: 100%;
  }
`;

interface CreditCardFormProps {
  hostedPageParams: HostedPageParams;
  prepopulatedFields: PrepopulateFields;
  errorCallback: Parameters<
    typeof renderForm
  >["0"]["clientErrorMessageCallback"];
}

export const CreditCardForm = ({
  hostedPageParams,
  prepopulatedFields,
  errorCallback,
}: CreditCardFormProps) => {
  // Use stringified objects to force re-render only if values change, not when object ref changes
  const stringifiedParams = JSON.stringify(hostedPageParams);
  const stringifiedFields = JSON.stringify(prepopulatedFields);

  useEffect(() => {
    renderForm({
      params: hostedPageParams,
      prepopulateFields: prepopulatedFields,
      clientErrorMessageCallback: errorCallback,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedParams, stringifiedFields]);

  return <ZuoraContainer id="zuora_payment" />;
};
