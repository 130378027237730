import { useIntl } from "react-intl";
import { t } from "translations";

import { track } from "apps-common/utils/analytics";

import { routes } from "../../routes";
import { SectionCard, PreviewRow, NavigationRow } from "../SectionCard";

interface Props {
  email: string;
}

export const AccountManagement = ({ email }: Props) => {
  const intl = useIntl();

  return (
    <SectionCard title={t("membership_hub_account_management")}>
      <PreviewRow
        label={t("membership_hub_email")}
        rowValues={[{ value: email, color: "grayLight" }]}
      />
      <PreviewRow
        label={t("membership_hub_password")}
        rowValues={[{ value: "************", color: "grayLight" }]}
      />
      <NavigationRow
        label={intl.formatMessage({ id: "membership_hub_edit_account_info" })}
        href={routes.updateAccountInfo}
        onClick={() => {
          track({
            event: "Link Clicked",
            payload: {
              cta: "edit_account",
              location: "body",
            },
          });
        }}
      />
    </SectionCard>
  );
};
