import { useRef } from "react";

import { useMutation } from "@tanstack/react-query";
import { emailRegex } from "common-utils";
import { RequestResetPasswordBody } from "oura-account-api-client";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { t } from "translations";

import { sendResetPasswordRequest } from "apps-common/hooks/AccountApi";
import { CTALoader, ErrorBanner, Header, SubmitButton, EmailField } from "ui";
import { Dialog } from "ui/components/Dialog";
import { StyledEmailIcon } from "ui/components/Icons";
import { MainContainer } from "ui/styles/containers";

import { routes } from "../../routes";

import { StyledForm } from "./StyledForm";

type FormValues = RequestResetPasswordBody & {
  serverError?: string;
};

export const RequestPasswordResetPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { clearErrors, formState, handleSubmit, register, setError } =
    useForm<FormValues>({
      criteriaMode: "all",
      mode: "onTouched",
    });
  const emailDialogRef = useRef<HTMLDialogElement>(null);

  const { mutate: emailSendMutation, isPending } = useMutation({
    mutationKey: ["requestResetPassword"],
    mutationFn: sendResetPasswordRequest,
  });

  const onSubmit = ({ email }: RequestResetPasswordBody) => {
    emailSendMutation(email, {
      onSuccess: () => emailDialogRef.current?.showModal(),
      onError: (error) => {
        let message: string = error.message;
        if (error.message === "RateLimited") {
          message = intl.formatMessage({
            id: "membership_reset_password_rate_limit_over",
          });
        }
        if (
          error.message === "UserNotFound" ||
          error.message === "BadRequest"
        ) {
          message = intl.formatMessage({
            id: "membership_reset_password_user_not_found",
          });
        }

        setError("serverError", { type: "server", message });
      },
    });
  };

  const onClose = () => {
    emailDialogRef.current?.close();
  };

  const emailSuccessDialog = (
    <Dialog
      ref={emailDialogRef}
      onCloseClick={onClose}
      icon={<StyledEmailIcon $width="32px" $height="32px" />}
      title={t("membership_hub_check_your_email")}
      textContents={[t("membership_hub_reset_password_email_instructions")]}
      primaryButton={{
        text: intl.formatMessage({
          id: "membership_hub_return_to_hub_home",
        }),
        onClick: () => navigate(routes.index),
      }}
    />
  );

  const SUB_TITLE = t("membership_hub_enter_email_for_reset_link");
  return (
    <>
      <Header
        pageType="create"
        appType="hub"
        title={intl.formatMessage({ id: "membership_hub_forgot_password" })}
        subTitle={SUB_TITLE}
      />
      <MainContainer $padding="10px 0 0 0">
        {formState.errors.serverError && (
          <ErrorBanner marginBottom="8px">
            {formState.errors.serverError.message}
          </ErrorBanner>
        )}
        <StyledForm
          onSubmit={handleSubmit(onSubmit)}
          onFocus={() => {
            clearErrors("serverError");
          }}
        >
          <EmailField
            showIcon={false}
            placeholder={intl.formatMessage({
              id: "membership_hub_enter_email",
            })}
            {...register("email", {
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "membership_hub_email_required_error_message",
                }),
              },
              pattern: {
                value: emailRegex,
                message: intl.formatMessage({
                  id: "membership_error_invalid_email",
                }),
              },
            })}
            error={formState.errors.email?.message}
          />
          <SubmitButton
            value={intl.formatMessage({
              id: "membership_hub_send_link_to_email",
            })}
            disabled={!formState.isValid || isPending}
          >
            {isPending ? <CTALoader /> : t("membership_hub_send_link_to_email")}
          </SubmitButton>
        </StyledForm>
        {emailSuccessDialog}
      </MainContainer>
    </>
  );
};
