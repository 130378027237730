import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { SignupMembershipType } from "../types";

export const upperCaseFirstLetter = (phrase: string) => {
  return phrase
    .split(" ")
    .map((word) =>
      word.replace(
        /^(\w)(\w*)$/,
        (_, p1: string, p2: string) => p1.toUpperCase() + p2.toLowerCase(),
      ),
    )
    .join(" ");
};

export const removeSpaces = (value: string) => value.replace(/\s+/g, "");

export const removeEmptyStringOrNilProperties = <T extends object>(input: T) =>
  omitBy<T>(input, (value) => value === "" || isNil(value));

export const isEntitlementUser = (type: SignupMembershipType) =>
  type === SignupMembershipType.ENTITLEMENT;

export const isGen2User = (type: SignupMembershipType) =>
  type === SignupMembershipType.LEGACY;

export const isPayingUser = (type: SignupMembershipType) =>
  type === SignupMembershipType.PAYING;
