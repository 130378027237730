// NOTE: These are translation keys which are translated in ErrorPage.tsx
export const ERROR_MAPPER = {
  signupUnknown: "membership_error_unknown",
  invalidTokenFormat: "membership_error_invalid_token_format",
  missingSignupToken: "membership_error_missing_signup_token",
  missingAccessToken: "membership_error_missing_access_token",

  errorOnGettingOfferings: "membership_error_on_getting_offerings",
  missingEmailSignupLogin: "membership_error_missing_email_signup_login",
  missingEmailHubLogin: "membership_error_missing_email_hub",
  missingPaymentMethodHubLogin:
    "membership_error_missing_payment_method_hub_login",

  errorOnExchangingTokenToJWT: "membership_error_exchange_token_jwt",
  oneTimeTokenAlreadyUsed: "membership_error_onetime_token_used",
  errorOnGettingMembershipOfferings: "membership_error_on_getting_offerings",
  errorOnGettingCountries: "membership_error_getting_countries",
  "Email address not found for cloud account":
    "membership_error_email_not_found_for_cloudaccount",

  "Not allowed to create subscription without entitlement":
    "membership_error_not_allowed_to_create_sub_without_entitlement",

  missingUserTypeFromOffering:
    "membership_error_missing_usertype_from_offering",

  failedPaymentSignatureCreation:
    "membership_error_failed_payment_signature_creation",

  noSubscriptionIsCreated: "membership_error_no_subscription_created",
  addressValidationFailed:
    "membership_error_membership_addressvalidation_failed",

  errorOnAddressValidation: "membership_error_on_address_validation",
  missingUrl: "membership_error_missing_url",
  errorOnAccountUpdate: "membership_error_on_account_update",
  errorOnGetAccount: "membership_error_on_get_account",
  missingTokenOnEmailVerification:
    "membership_error_on_verify_email_magic_link_token_missing",
  missingResetPasswordToken: "membership_error_on_reset_password_token_missing",
  verifyEmailLinkExpired: "membership_hub_verify_email_magic_link_expired",
  errorOnRenewSubscription: "membership_error_on_renew_subscription",
  errorOnChangingSubscriptionPlan:
    "membership_error_on_change_subscription_plan",
  errorOnB2CNoSubNoAccessOrNullMembershipType:
    "membership_hub_b2c_no_subscription_no_access",
  errorOnUnverifiedAccount: "membership_hub_unverified_account",
  hubGeneralError: "membership_hub_general_error",
  noProductFound: "membership_error_no_product_found",
  noAddressFound: "membership_hub_no_address_found", // check it is not used for placeholder accounts (they dont have address)
  noUserAddressFormFound: "membership_error_go_home_to_address_data",
  moiNoAccountFound: "membership_hub_moi_error_signup_required",
} as const;

class CustomError extends Error {
  internalError: unknown;
  constructor(errorKey: keyof typeof ERROR_MAPPER, error?: unknown) {
    super(ERROR_MAPPER[errorKey]);
    this.internalError = error;
  }
}

export function throwError(
  key: keyof typeof ERROR_MAPPER,
  error?: unknown,
): never {
  throw new CustomError(key, error);
}
