/* eslint-disable @typescript-eslint/no-unsafe-call */
import {
  StartPaypalExpressCheckoutInput,
  startPaypalExpressCheckout,
} from "../graphql/startPaypalExpressCheckout";
import { track } from "../utils/analytics";

import { logger } from "./logger";

const PAYPAL_ENV = import.meta.env.VITE_PAYPAL_ENV;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paypal: any;
    paypalCheckoutReady?: () => void;
  }
}

export const initPaypal = (componentId: string, paypalMerchantId: string) => {
  window.paypalCheckoutReady = function () {
    window.paypal.checkout.setup(paypalMerchantId, {
      environment: PAYPAL_ENV,
      container: componentId,
      size: "medium",
      color: "blue",
      shape: "pill",
      tagline: "false",
    });
  };
};

export const onPaypalStart = async (req: StartPaypalExpressCheckoutInput) => {
  const { isUnitedStates, currency } = req;
  logger.debug("Calling Paypal initXO", { isUnitedStates, currency });
  window.paypal.checkout.initXO();

  try {
    const resp = await startPaypalExpressCheckout(req);

    const { url } = resp.startPaypalExpressCheckout;
    logger.debug("Calling Paypal startFlow", { isUnitedStates, currency });
    window.paypal.checkout.startFlow(url);
    track({
      event: "Modal Opened",
      payload: {
        cta: "paypal_checkout",
        action: "payment_method_added",
        location: "membership_onboarding",
      },
    });
  } catch (err) {
    window.paypal.checkout.closeFlow();
    throw err;
  }
};
