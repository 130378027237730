import { styled } from "styled-components";

import { media } from "../themes/signup";

export const HeroRing = styled.img`
  position: center;
  background: linear-gradient(180deg, rgb(196 196 196 / 0%) 0%, #000 100%),
    url("/background.webp");
  background-size: cover;
  width: 100%;
`;
export const HeroNoRing = styled.div`
  position: center;
  background: linear-gradient(180deg, rgb(196 196 196 / 0%) 0%, #000 100%),
    url("/background.webp");
  background-size: cover;
`;

export const RingImage = styled.img`
  width: 80%;

  ${media.large`
   width: 393px;
   `}

  ${media.xlarge`
   transform: translateY(-20%);
   `}
`;
