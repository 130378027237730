import { styled } from "styled-components";
import { t } from "translations";

import { Link } from "../../styles/text";
import { media } from "../../themes/signup";

import { FooterProps, WebsiteLink } from "./types";

const WEBSITE_LINKS: WebsiteLink[] = [
  {
    name: "membership_signup_terms_conditions",
    href: "https://ouraring.com/terms-and-conditions",
  },
  {
    name: "membership_signup_privacy_policy",
    href: "https://ouraring.com/privacy-policy",
  },
  {
    name: "membership_signup_ip_notice",
    href: "https://ouraring.com/intellectual-property-notice",
  },
  {
    name: "membership_signup_accessibility",
    href: "https://ouraring.com/accessibility",
  },
  {
    name: "membership_signup_support",
    href: "https://support.ouraring.com/hc",
  },
];

const WeblinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  a {
    width: 50%;
  }
  ${media.xlarge`
    flex-wrap: nowrap;
    gap: 20px;
    a {
    width: auto;
    }
  `}
`;

export const WebsiteLinks = ({ onLinkClick }: FooterProps) => {
  return (
    <WeblinkWrapper>
      {WEBSITE_LINKS.map((link: WebsiteLink) => (
        <Link
          onClick={(e) => {
            onLinkClick?.(link, e);
          }}
          target="_blank"
          href={link.href}
          $fontSize="14px"
          $color="white2"
          $textAlign="left"
          key={link.name}
        >
          {t(link.name)}
        </Link>
      ))}
    </WeblinkWrapper>
  );
};
