import { styled } from "styled-components";

import { media } from "../themes/signup";

import { Button } from "./Button";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-self: flex-start;

  ${Button}[type="submit"] {
    align-self: center;
    margin: 40px 0;
  }

  ${media.xlarge`
    max-width: 60%;

    ${Button}[type="submit"] {
      align-self: flex-end;
    }
  `}
`;

type FormProps = React.FormHTMLAttributes<HTMLFormElement>;

export const Form = (props: FormProps) => <StyledForm {...props} />;

type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type">;

export const SubmitButton = (props: ButtonProps) => (
  <Button type="submit" {...props} />
);
