import { forwardRef } from "react";

import { TextField, TextFieldProps } from "./TextField";

interface EmailFieldProps extends TextFieldProps {
  showIcon?: boolean;
}

export const EmailField = forwardRef<HTMLInputElement, EmailFieldProps>(
  ({ showIcon = true, ...props }, ref) => (
    <TextField
      {...props}
      ref={ref}
      icon={showIcon ? "email" : undefined}
      aria-label="email"
      type="email"
    />
  ),
);
