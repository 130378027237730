import { ButtonHTMLAttributes } from "react";

import { styled } from "styled-components";
import { t } from "translations";

const StyledButton = styled.button(
  ({ theme }) => `
  padding: 0 10px;
  background: none;
  border: none;
  color: ${theme.colors.helsinkiBlue};
  font-family: ${theme.fonts};
  font-size: ${theme.small};
  font-weight: 700;
  line-height: 150%;
  cursor: pointer;
`,
);

type BackButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export const BackButton = (props: BackButtonProps) => (
  <StyledButton {...props}>← {t("membership_signup_button_back")}</StyledButton>
);
