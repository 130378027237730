import { useRef } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { t } from "translations";

import { PaymentDisclaimer } from "apps-common/components/PaymentDisclaimer";
import { ProductSelector } from "apps-common/components/ProductSelector";
import { useChangeBillingPeriod } from "apps-common/hooks/useChangeBillingPeriod";
import { useGetAccount } from "apps-common/hooks/useGetAccount";
import { BillingPeriod } from "apps-common/types";
import { track } from "apps-common/utils/analytics";
import { throwError } from "apps-common/utils/errorHandler";
import { ratePlansFromSubscription } from "apps-common/utils/getProduct";
import { logger } from "apps-common/utils/logger";
import { periodToProductPlanNameKey } from "apps-common/utils/productLocalization";
import {
  Button,
  CTALoader,
  ErrorBanner,
  Form,
  Header,
  Loader,
  SubmitButton,
} from "ui";
import { Dialog } from "ui/components/Dialog";
import { OuraLogo } from "ui/components/Icons";
import { MainContainer } from "ui/styles/containers";

import { routes } from "../routes";

const StyledForm = styled(Form)`
  & > div {
    margin-bottom: 160px;
  }
  ${Button}[type="submit"] {
    margin-bottom: 0;
  }
`;

export const UpdatePlanPage = () => {
  const navigate = useNavigate();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const queryClient = useQueryClient();

  const { data: accountData, isFetching, error } = useGetAccount();

  if (error) {
    logger.error("Fetching account data on update plan page failed", { error });
  }

  const {
    mutate,
    data: mutationData,
    error: errorOnChangingPlan,
    isPending,
  } = useChangeBillingPeriod();

  if (errorOnChangingPlan) {
    throw throwError("errorOnChangingSubscriptionPlan", error);
  }

  const currentSubscription = accountData?.account.currentSubscription;

  // Access control should prevent users without subscription landing here
  if (!currentSubscription) {
    throw throwError(
      "hubGeneralError",
      "User on UpdatePlanPage missing a subscription",
    );
  }

  const { recurringFee: currentPlan } = currentSubscription;
  // recurringFee is null for Placeholders. Access control should prevent them landing here
  if (!currentPlan) {
    throw throwError(
      "hubGeneralError",
      "Subscription without recurringFee (placeholder?), on UpdatePlanPage",
    );
  }

  const ratePlans = ratePlansFromSubscription(currentSubscription);

  const { formState, getValues, handleSubmit, register } = useForm<{
    product: BillingPeriod;
  }>({
    values: {
      product: currentPlan.billingPeriod,
    },
  });

  const selectedRatePlan =
    ratePlans.find((p) => p.billingPeriod === getValues("product")) ??
    currentPlan;

  const { billingPeriod, amount, currency } = selectedRatePlan;

  const title = t("membership_hub_product_page_title");
  const subTitle = t("membership_hub_update_product_page_subtitle");
  const planName = t(periodToProductPlanNameKey(billingPeriod));
  const productPrice = `${amount.toLocaleString(undefined, {
    style: "currency",
    currency: currency,
  })} ${currency}`;

  const renewalDate = new Date(
    mutationData?.nextBillingDate ?? "",
  ).toLocaleDateString(undefined, {
    year: "numeric",
    month: "long",
    day: "2-digit",
  });

  const onSubmit = handleSubmit((data) => {
    track({
      event: "CTA Clicked",
      payload: { cta: "update", action: "update_plan" },
    });

    const { product: billingPeriod } = data;

    mutate(
      {
        billingPeriod,
      },
      {
        onSuccess: () => {
          logger.info("Plan changed successfully");
          track({
            event: "Membership Plan Updated",
            payload: {
              productType: billingPeriod,
            },
          });
          dialogRef.current?.showModal();
        },
        onError: (error) => {
          logger.error("Updating plan failed", { error });
        },
      },
    );
  });

  const backRoute = routes.membershipPlanOverview;
  const dialogButtonText = t("membership_hub_continue");

  const onDialogClose = () => {
    queryClient.invalidateQueries({ queryKey: ["getPaymentMethods"] });
    navigate(backRoute);
  };

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={title}
        subTitle={subTitle}
        onBackClick={() => navigate(backRoute)}
      />
      <MainContainer>
        <StyledForm onSubmit={onSubmit}>
          {error && <ErrorBanner>{error.message}</ErrorBanner>}
          {isFetching && <Loader isWholePage={false} />}
          {!isFetching && (
            <ProductSelector
              ratePlans={ratePlans}
              {...register("product", { required: true })}
            />
          )}
          <SubmitButton
            disabled={isFetching || isPending || !formState.isValid}
          >
            {isPending ? <CTALoader /> : t("membership_hub_update")}
          </SubmitButton>
          <PaymentDisclaimer />
        </StyledForm>
        <Dialog
          ref={dialogRef}
          onCloseClick={onDialogClose}
          icon={<OuraLogo />}
          title={planName}
          textContents={[
            t("membership_hub_plan_confirm_modal_text", {
              product_plan_name: planName,
              subscription_price: productPrice,
            }),
          ]}
          finePrint={[
            t("membership_hub_confirm_modal_plan_renew", {
              renewal_date: renewalDate,
            }),
          ]}
          primaryButton={{
            text: dialogButtonText,
            onClick: onDialogClose,
          }}
        />
      </MainContainer>
    </>
  );
};
