import { useGateValue } from "@statsig/react-bindings";

export enum Flags {
  MOI_AUTH = "moi_auth",
  ADDRESS_VALIDATION_SUGGESTIONS = "address_validation_suggestions",
  HSA = "sika_fsa_hsa",
  OREO = "oreo",
}

export const useFlag = (key: Flags): boolean => {
  return useGateValue(key);
};
