import { styled } from "styled-components";
import { t } from "translations";

interface LogoutButtonProps {
  onClick: () => void;
}

const StyledLogoutButton = styled.button(
  ({ theme }) => `
    appearance: none;
    display: flex;
    height: fit-content;
    padding: 4px 15px;
    cursor: pointer;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    font-weight: 700;
    background: ${theme.colors.transparent};
    border: 1px ${theme.colors.white} solid;
    border-radius: 50px;
`,
);

export const LogoutButton = ({ onClick }: LogoutButtonProps) => {
  return (
    <StyledLogoutButton onClick={onClick}>
      {t("membership_hub_logout")}
    </StyledLogoutButton>
  );
};
