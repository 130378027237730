import { useEffect } from "react";

import { useLocation } from "react-router-dom";

// This is to make sure that when we navigate to a new page, the page is scrolled to the top on mobile devices
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
