import { PropsWithChildren } from "react";

import { css, styled } from "styled-components";

import { Background } from "../styles/layout";
import { Paragraph } from "../styles/text";

interface ErrorBannerProps extends PropsWithChildren {
  marginBottom?: string;
}

const PaddedBg = styled(Background)(
  ({ theme }) => css`
    display: flex;
    padding: 10px;
    background-color: ${theme.colors.errorBackground};
    width: 100%;

    p {
      margin: 0;
      padding: 6px 0 5px;
      font-size: 14px;
      line-height: 142%;
    }
  `,
);

const VerticalColor = styled.div(
  ({ theme }) => `
  background: ${theme.colors.bannerErrorState};
  width: 3px;
  height: auto;
  border-radius: 8px;
  margin-right: 18px;
`,
);

export const ErrorBanner = (props: ErrorBannerProps) => (
  <PaddedBg $marginBottom={props.marginBottom}>
    <VerticalColor />
    <Paragraph $fontSize="small" role="alert">
      {props.children}
    </Paragraph>
  </PaddedBg>
);
