import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "apps-common/context/auth";

import {
  UserAccessCheck,
  UserAccessCheckProps,
} from "../components/UserAccessCheck";

// Always checks the user's login state first before further executing codes to prevent calling any endpoints or rendering any components that require token.
export const WithAuthAndAccessCheck = ({
  userAccessRights,
  children,
}: UserAccessCheckProps) => {
  const navigate = useNavigate();
  const nextRoute = window.location.pathname;
  const { loggedIn } = useAuth();
  useEffect(() => {
    if (!loggedIn) {
      navigate(`/login?next=${nextRoute}`);
    }
  }, [navigate, nextRoute, loggedIn]);

  if (!loggedIn) {
    return null;
  }

  return (
    <UserAccessCheck userAccessRights={userAccessRights}>
      {children}
    </UserAccessCheck>
  );
};
