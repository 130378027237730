import { Address } from "../../types";

export interface FormData {
  billingAddress: Address;
  shippingAddress: Address;
}

export const emptyAddress: Address = {
  firstName: "",
  lastName: "",
  address1: "",
  city: "",
  country: "",
  postalCode: "",
};

export type FormValues = FormData & {
  serverError?: string;
  isUnifiedAddress: boolean;
};

export const emptyForm: FormValues = {
  billingAddress: emptyAddress,
  shippingAddress: emptyAddress,
  isUnifiedAddress: true,
};
