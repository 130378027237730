import isEqual from "lodash/isEqual";
import { GetSignupResponse } from "oura-account-api-client";
import { StateCreator } from "zustand";

import { FormData } from "../components/AddressForm/types";
import {
  Address,
  PaymentGateways,
  RatePlan,
  SignupMembershipType,
} from "../types";
import { logger } from "../utils/logger";

const sliceResetFns = new Set<() => void>();

export const resetAllSlices = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

export interface AddressSlice {
  userAddressForm?: {
    billingAddress: Address;
    shippingAddress: Address;
    isUnifiedAddress: boolean;
  };
  billingCountryIsoAlpha3Code: string;
  gateways: PaymentGateways;
  setUserAddressForm: (form: FormData) => void;
  setBillingCountryIsoAlpha3Code: (code: string) => void;
  setGateways: (gateways: PaymentGateways) => void;
}

export interface MemberSlice {
  email: string;
  accountId: string;
  setEmail: (email: string) => void;
  setAccountId: (token: string) => void;
}

export interface ProductSlice {
  selectedRatePlan: RatePlan | undefined;
  setRatePlan: (selectedRatePlan: RatePlan) => void;
}

export interface SignupInfo extends GetSignupResponse {
  token: string;
}
export interface SignupSlice {
  signupInfo: SignupInfo;
  userType: SignupMembershipType;
  setSignupInfo: (signupInfo: SignupInfo) => void;
  setUserType: (userType: SignupMembershipType) => void;
}

export interface HubSlice {
  existingAddressInUse: boolean;
  setExistingAddressInUse: (existingAddressInUse: boolean) => void;
}

const initialAddressState = {
  userAddressForm: undefined,
  billingCountryIsoAlpha3Code: "",
  gateways: {
    hostedPageId: "",
    creditCardGatewayName: "",
    paypalGatewayName: "",
    sikaHealthGatewayName: "",
  },
};
export const createAddressSlice: StateCreator<AddressSlice> = (set) => {
  sliceResetFns.add(() => set(initialAddressState));
  return {
    ...initialAddressState,
    setUserAddressForm: (form: FormData) =>
      set({
        userAddressForm: {
          ...form,
          isUnifiedAddress: isEqual(form.billingAddress, form.shippingAddress),
        },
      }),
    setBillingCountryIsoAlpha3Code: (code: string) =>
      set({ billingCountryIsoAlpha3Code: code }),
    setGateways: (gateways) => set({ gateways }),
  };
};

const initialMemberState = {
  email: "",
  accountId: "",
};
export const createMemberSlice: StateCreator<MemberSlice> = (set) => {
  sliceResetFns.add(() => set(initialMemberState));
  return {
    ...initialMemberState,
    setEmail: (email: string) => set({ email }),
    setAccountId: (accountId: string) => {
      logger.setContextProperty("accountId", accountId);
      return set({ accountId });
    },
  };
};

const initialProductState = {
  selectedRatePlan: undefined,
};
export const createProductSlice: StateCreator<ProductSlice> = (set) => {
  sliceResetFns.add(() => set(initialProductState));
  return {
    ...initialProductState,
    setRatePlan: (selectedRatePlan: RatePlan) => set({ selectedRatePlan }),
  };
};

const initialSignupState: {
  signupInfo: SignupInfo;
  userType: SignupMembershipType;
} = {
  signupInfo: {
    email: "",
    ringSerialStatus: "NotRegistered",
    termsOfServiceVersion: "",
    privacyPolicyVersion: "",
    accountStatus: "New",
    token: "",
  },
  userType: SignupMembershipType.LEGACY,
};
export const createSignupSlice: StateCreator<SignupSlice> = (set) => {
  sliceResetFns.add(() => set(initialSignupState));
  return {
    ...initialSignupState,
    setSignupInfo: (signupInfo: SignupInfo) => set({ signupInfo }),
    setUserType: (userType: SignupMembershipType) => set({ userType }),
  };
};

const initialHubState = {
  existingAddressInUse: false,
};

export const createHubSlice: StateCreator<HubSlice> = (set) => {
  sliceResetFns.add(() => set(initialHubState));
  return {
    ...initialHubState,
    setExistingAddressInUse: (existingAddressInUse: boolean) =>
      set({ existingAddressInUse }),
  };
};
