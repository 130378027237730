export const routes = {
  index: "/",
  login: "/login",
  paymentDetailsPageSelectAddressUsage: "/payment-details",
  reviewPaymentMethodForRenew: "/renew-membership",
  addressForm: "/add-address-info",
  selectPlan: "/update-payment-method/plan",
  updatePaymentMethod: "/update-payment-method",
  updateAccountInfo: "/update-account-information",
  billingHistory: "/billing-history",
  paymentMethodCallback: "/payment-method/card-callback",
  paypalCallback: "/payment-method/paypal-callback",
  hsaCallback: "/payment-method/hsa-callback",
  verifyNewEmail: "/verify-email",
  updatePaymentMethodSuccess: "/update-payment-method/success",
  updatePaymentMethodSuccessRenew: "/update-payment-method/success/renew",
  requestPasswordReset: "/request-password-reset",
  forgotPasswordResetSetPassword: "/reset-password",
  memberExperience: "/member-experience",
  membershipPlanOverview: "/membership-plan",
  updatePlan: "/update-membership-plan",
  error: "/error",
} as const;
