import { ExtendableError } from "./ExtendableError";

import type { GraphQLError } from "graphql";

const ERROR_CODES = [
  "ACCOUNT_NOT_FOUND",
  "BAD_USER_INPUT",
  "CLOUD_ACCOUNT_REQUIRED",
  "COUNTRY_NOT_SUPPORTED",
  "CURRENCY_NOT_SUPPORTED",
  "EMAIL_ADDRESS_REQUIRED",
  "ENTITLEMENT_NOT_ALLOWED",
  "ENTITLEMENT_REQUIRED",
  "INCORRECT_PRODUCT",
  "INVALID_ACCOUNT_TYPE",
  "INVALID_PAYMENT_GATEWAY",
  "INVALID_SUBSCRIPTION_STATE",
  "INVALID_SUBSCRIPTION_TYPE",
  "INVALID_UPSERT_PAYMENT_METHOD_REQUEST",
  "PAYMENT_GATEWAY",
  "PRODUCT_NOT_FOUND",
  "PRODUCT_TYPE_NOT_ALLOWED",
  "PRODUCT_TYPE_NOT_SUPPORTED",
  "RENEW_LIMIT_REACHED",
  "RING_SERIAL_NUMBER_REQUIRED",
  "SUBSCRIPTION_ALREADY_EXISTS",
  "PAYPAL_BILLING_AGREEMENT_CANCELLED",
] as const;

interface Errors {
  AccountNotFound: {
    code: "ACCOUNT_NOT_FOUND";
    message: "Zuora account not found";
  };

  BadUserInput: {
    code: "BAD_USER_INPUT";
    message: string;
    extensions: {
      field: string;
      context: { arg: string; value: number }[];
    };
  };

  CloudAccountRequired: {
    code: "CLOUD_ACCOUNT_REQUIRED";
    message: "No account found with accountId";
  };

  CountryNotSupported: {
    code: "COUNTRY_NOT_SUPPORTED";
    message: "Trial member has invalid country";
  };

  CurrencyNotSupported: {
    code: "CURRENCY_NOT_SUPPORTED";
    message:
      | "Given currency does not match country currency"
      | "Given currency is not supported for given country for new subscriptions"
      | "Unable to find currency for member";
  };

  EmailAddressRequired: {
    code: "EMAIL_ADDRESS_REQUIRED";
    message: "Email address not found for cloud account";
  };

  EntitlementNotAllowed: {
    code: "ENTITLEMENT_NOT_ALLOWED";
    message: "Creating subscription for b2b or lifetime entitlement not allowed";
  };

  EntitlementRequired: {
    code: "ENTITLEMENT_REQUIRED";
    message: "Not allowed to create subscription without entitlement";
  };

  IncorrectProduct: {
    code: "INCORRECT_PRODUCT";
    message: "No suitable product in catalog for renewal";
  };

  InvalidAccountType: {
    code: "INVALID_ACCOUNT_TYPE";
    message: "Cannot renew expired subscription without Zuora account.";
  };

  InvalidGateway: {
    code: "INVALID_PAYMENT_GATEWAY";
    message:
      | "Payment gateway does not exist"
      | "Payment gateway incorrect for country";
  };

  InvalidSubscriptionState: {
    code: "INVALID_SUBSCRIPTION_STATE";
    message: "Country or currency change cannot be done if subscription is not expired";
  };

  InvalidSubscriptionType: {
    code: "INVALID_SUBSCRIPTION_TYPE";
    message: "Cannot renew expired placeholder subscription with PAYMENT_METHOD_REQUIRED";
  };

  InvalidUpsertPaymentMethod: {
    code: "INVALID_UPSERT_PAYMENT_METHOD_REQUEST";
    message:
      | "Attempt to set payment method when no subscription exists"
      | "Cannot change country via payment method update if there is active subscriptions"
      | "Cannot change account currency via payment method update if there is active subscriptions"
      | "Cannot change currency because account is in dunning";
  };

  PaymentGateway: {
    code: "PAYMENT_GATEWAY";
    message: string;
    extensions: {
      supaErrorCode: number;
      gatewayErrorCode: string;
      gatewayMessage: string;
    };
  };

  ProductNotFound: {
    code: "PRODUCT_NOT_FOUND";
    message: "No product found for customer";
  };

  ProductTypeNotAllowed: {
    code: "PRODUCT_TYPE_NOT_ALLOWED";
    message:
      | "Only eCommerce and retail products allowed"
      | "No lifetime subscriptions allowed";
  };

  ProductTypeNotSupported: {
    code: "PRODUCT_TYPE_NOT_SUPPORTED";
    message:
      | "No suitable product in catalog for given country to update to!"
      | "No suitable product in catalog for given country to update to when changing currency!"
      | "Trial membership not available in requested country";
  };

  RenewLimitReached: {
    code: "RENEW_LIMIT_REACHED";
    message: "Account already has more than 6 times the final dunning level reached. Renew is blocked.";
  };

  RingSerialNumberRequired: {
    code: "RING_SERIAL_NUMBER_REQUIRED";
    message: "Ring serial number not found for account";
  };

  SubscriptionAlreadyExists: {
    code: "SUBSCRIPTION_ALREADY_EXISTS";
    message:
      | "Account already has subscription"
      | "Existing subscription found. Cannot create new with entitlement";
  };

  PaypalBillingAgreementCancelled: {
    code: "PAYPAL_BILLING_AGREEMENT_CANCELLED";
    message: "Paypal billing agreement has been cancelled. Please update your payment method to continue your membership";
  };
}

type ErrorTypes = Errors[keyof Errors];

class GQLError extends ExtendableError {
  code: ErrorTypes["code"];
  message: ErrorTypes["message"];
  extensions: unknown;
  originalError: GraphQLError;

  constructor(error: GraphQLError) {
    super(error.message);
    this.code = error.extensions.code as ErrorTypes["code"];
    this.message = error.message;
    this.extensions = error.extensions;
    this.originalError = error;
    this.name = "GQLError"; // https://github.com/vitejs/vite/issues/13727
  }
}

// We cannot expose class as a type with discriminated union (similar to ErrorTypes), so create a new type and a type-guard.
// Now SupaGQLError has discriminated typing per code, and otherwise functions similarly to Error classes (no instanceof however :( )
export type SupaGQLError = ErrorTypes & Error;

export const isSupaGQLError = (error: unknown): error is SupaGQLError => {
  return error instanceof GQLError && ERROR_CODES.includes(error.code);
};

export const createSupaGQLError = (error: GraphQLError): SupaGQLError => {
  return new GQLError(error) as SupaGQLError;
};
