import { styled } from "styled-components";
import { t } from "translations";

import { Paragraph } from "ui/styles/text";
import { media } from "ui/themes/signup";

const StyledP = styled(Paragraph)`
  padding: 24px 20px 0;
  text-align: justify;
  ${media.large`
  padding: 24px 0 0;
`}
`;

export const PaymentDisclaimer = () => (
  <StyledP $fontSize="small" $lineHeight="140%" $color="grayLight">
    {t("membership_signup_payment_disclaimer")}
  </StyledP>
);
